import React, { createContext, createElement, LegacyRef, useContext } from 'react';

const FormV2Context = createContext<{
  updateHeaders: () => void;
}>({
  updateHeaders: () => {},
});

export function useUpdateHeaders() {
  return useContext(FormV2Context).updateHeaders;
}

export default FormV2Context;
