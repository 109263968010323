import { Grid } from '@material-ui/core';
import { defaultTo, find, zip } from 'lodash';
import get from 'lodash/get';
import React from 'react';
import { breakpoint } from '../../../se/utilities/responsive';
import { roundValue } from '../Analytics';
import BarLayout, { barColors } from '../analytics/BarLayout';
import AnalyticsPanel from '../analytics/components/AnalyticsPanel';
import PieChart from '../analytics/PieChart';

const utilizationBreakdown = ({ operationRoomBookings, scheduledPatients }) => {
  const utilization = defaultTo(scheduledPatients.value, 0);
  const bookings = defaultTo(operationRoomBookings.value, 0);

  const unutilizedBlocks = Math.max(bookings - utilization, 0);
  const vacantBlocks = 100 - utilization - unutilizedBlocks;

  return { utilization, unutilizedBlocks, vacantBlocks };
};

const calculateBookingDataPoint = point => {
  const utilization = defaultTo(point['bar-0'], 0);
  const bookings = defaultTo(point['bar-1'], 0);

  const unutilizedBlocks = Math.max(bookings - utilization, 0);
  const vacantBlocks = 100 - utilization - unutilizedBlocks;

  return {
    ...point,
    'bar-0': roundValue(utilization),
    'bar-1': roundValue(unutilizedBlocks),
    'bar-2': roundValue(vacantBlocks),
  };
};

const BookingAnalytics = ({ statistics = [], loading }) => {
  const operationRoomBookings = defaultTo(
    find(statistics, stat => stat.id === 'operationRoomBookings'),
    {}
  );
  const scheduledPatients = defaultTo(
    find(statistics, stat => stat.id === 'scheduledPatients'),
    {}
  );
  const showBookingStats = operationRoomBookings && scheduledPatients;
  const { utilization, unutilizedBlocks, vacantBlocks } = utilizationBreakdown({
    operationRoomBookings,
    scheduledPatients,
  });
  const isMobile = window.innerWidth < breakpoint.md;

  const bars = [
    {
      ...scheduledPatients,
      value: utilization,
      title: 'Utilization',
    },
    { ...operationRoomBookings, title: 'Unutilized Bookings' },
  ];

  const distribution = zip(...bars.map(b => get(b, 'distribution')))
    .map(bars =>
      bars.reduce((acc, curr, i) => ({ ...acc, [`bar-${i}`]: curr.value, date: curr.date, name: curr.name }), {})
    )
    .map(calculateBookingDataPoint);

  const barsData = [
    ...bars.map((_, i) => ({ ...bars[i], id: `bar-${i}`, color: barColors[i] })),
    { id: `bar-2`, title: 'Vacancy', unit: '%', color: barColors[2] },
  ];

  return showBookingStats ? (
    <Grid container spacing={1} loading={loading}>
      <Grid item md={6}>
        <AnalyticsPanel title={'Block Utilization Breakdown'}>
          <BarLayout key={`block-schedule-dist`} barsData={barsData} distribution={distribution} />
        </AnalyticsPanel>
      </Grid>
      <Grid item md={3}>
        <AnalyticsPanel title={'Block Utilization Breakdown'}>
          <PieChart
            data={[
              { name: 'Utilization', value: utilization },
              { name: 'Unutilized Bookings', value: unutilizedBlocks },
              { name: 'Vacancy', value: vacantBlocks, legendColor: 'white' },
            ]}
            colors={barColors}
          />
        </AnalyticsPanel>
      </Grid>
    </Grid>
  ) : null;
};

export default BookingAnalytics;
