import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Distribution from './Distribution';

export default ({
  title,
  value,
  distribution,
  unit,
  ratio,
  hideValue,
  tooltipLabel,
  isDecimal,
  children,
  noValue,
  color = undefined,
  greenLineY,
  prevStat,
  data,
}) => {
  const classes = useStyles();
  const computedValue =
    noValue || hideValue ? <span>&nbsp;</span> : [isDecimal ? value.toFixed(1) : value.toFixed(0), unit].join(' ');
  const difference = data?.value - prevStat?.value;
  const formattedDifference = Number.isInteger(difference) ? difference.toString() : difference.toFixed(1);
  const displayDifference = difference >= 0 ? `+${formattedDifference}` : formattedDifference;
  const percentDifference =
    prevStat?.value !== 0 && data?.value !== 0
      ? ((data?.value - prevStat?.value) / prevStat?.value) * 100
      : prevStat?.value === 0 && data?.value === 0
      ? 0
      : prevStat?.value === 0
      ? 100
      : -100;

  const roundedPercentDifference = percentDifference.toFixed(1);
  const absolutePercentDifference = Math.abs(roundedPercentDifference) + '%';
  let textColor = 'white';
  let arrowIcon = '';

  if (percentDifference > 0) {
    textColor = '#41d261';
    if (
      data?.id === 'averageWaitingRoomTime' ||
      data?.id === 'averagePreOPTime' ||
      data?.id === 'averageORTime' ||
      data?.id === 'averagePACUTime' ||
      data?.id === 'averageCleaningTime' ||
      data?.id === 'averagePopiTime' ||
      data?.id === 'averagePostOpTime' ||
      data?.id === 'averageReadyForORtoPreOpExit'
    ) {
      textColor = '#ff5252';
    }
    arrowIcon = '▲';
  } else if (percentDifference < 0) {
    textColor = '#ff5252';
    arrowIcon = '▼';
    if (
      data?.id === 'averageWaitingRoomTime' ||
      data?.id === 'averagePreOPTime' ||
      data?.id === 'averageORTime' ||
      data?.id === 'averagePACUTime' ||
      data?.id === 'averageCleaningTime' ||
      data?.id === 'averagePopiTime' ||
      data?.id === 'averagePostOpTime' ||
      data?.id === 'averageReadyForORtoPreOpExit'
    ) {
      textColor = '#41d261';
    }
  }

  return (
    <Box className={classes.root}>
      {!noValue && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="baseline" flexWrap="wrap" style={{ gap: 8 }}>
            <Typography component="div" variant="h3" style={{ color }}>
              {computedValue}
            </Typography>
            <Typography style={{ color: textColor }}>
              {displayDifference} {` (${absolutePercentDifference}) `} {arrowIcon}
            </Typography>
          </Box>
        </Box>
      )}

      {children ? (
        children
      ) : (
        <Distribution
          color={color}
          distribution={distribution}
          unit={unit}
          label={title}
          ratio={ratio}
          tooltipLabel={tooltipLabel}
          greenLineY={greenLineY}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));
