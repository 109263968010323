import { get } from 'lodash';
import { select, selectList } from '../../selectors.js';
import { useQuestionnaireValueViaSelector } from '../../../components/entities/surgeonProcedures/predefinedForms/hooks.jsx';

export interface Medication {
  name: string;
  dosage: string;
  route: string;
  purpose: string;
  frequency: string;
}

interface MedicationsQuestionary {
  medications: Medication[];
  answered: boolean;
  emptyName: string;
}

export const useMedications = (): MedicationsQuestionary => {
  const emptyName = 'NO KNOWN MEDICATIONS';

  const list = useQuestionnaireValueViaSelector(selectList('medications.medicationList.yes.medication_list'));

  const q1 = useQuestionnaireValueViaSelector(select('medications.NSAIDS'));
  const q2 = useQuestionnaireValueViaSelector(select(`medications.medicationList`));
  const q3 = useQuestionnaireValueViaSelector(select(`medications.steroids`));
  
  const hasQ1 = hasAnswered(q1);
  const hasQ2 = hasAnswered(q2);
  const hasQ3 = hasAnswered(q3);

  const answered = hasQ1 !== undefined && hasQ2 !== undefined && hasQ3 !== undefined;

  const medications = extractMedications(list);

  return { medications, answered, emptyName };
};

function extractMedications(list: any[]): Medication[] {
  return list.map(m => ({
    name: get(m, 'schema.name.text', ''),
    dosage: get(m, 'schema.dosage.text', ''),
    route: get(m, 'schema.route.option', ''),
    purpose: get(m, 'schema.purpose.text', ''),
    frequency: get(m, 'schema.frequency.option', ''),
  }));
}

function hasAnswered(item: any): boolean {  
  const value = get(item, 'yes');
  return value != null;
}
