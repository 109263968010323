import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { withTheme } from '../se/theme';
import ospitek_logo_white from '../assets/images/ospitek_logo_white.svg';
import ospitek_logo_blue from '../assets/images/ospitek_logo_blue.svg';
import responsive from '../se/utilities/responsive';
import { withScope } from '../contexts/ScopeContext';
import Filters from './pages/kiosk/schedule/Filters';

const HospitalInfoRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  color: ${withTheme(theme => theme.textColor.string())};
  font-size: 1.25rem;
  font-weight: ${withTheme(theme => theme.fontWeight.medium)};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0 2.5rem 0.5rem;

  ${responsive.md.andSmaller`
    font-size: .75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0;
    padding-bottom: 0;
  `};
`;

const FilterBox = styled.div`
  > div {
    flex: 1;
    display: flex;
    align-items: center;
  }
`;

const LogoHolder = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 6em;
`;

export const withDate = updateInterval => Component =>
  class WithDate extends React.Component {
    componentDidMount() {
      this.updateInterval = setInterval(() => this.forceUpdate(), updateInterval);
    }

    componentWillUnmount() {
      clearInterval(this.updateInterval);
    }

    render() {
      return <Component {...this.props} date={new Date()} />;
    }
  };

export const HospitalInfo = ({ scope, date, onChange }) => {
  const classes = useStyles();
  const theme = useTheme();

  const logo = theme.palette.type === 'light' ? ospitek_logo_blue : ospitek_logo_white;

  return (
    <HospitalInfoRoot>
      <LogoHolder>
        <Logo src={logo} />
      </LogoHolder>
      <Typography className={classes.hospitalName}>{get(scope, 'hospital.name')}</Typography>
      <FilterBox>
        <Filters date={date} withoutPhysician onChange={onChange} />
      </FilterBox>
    </HospitalInfoRoot>
  );
};

const useStyles = makeStyles(theme => ({
  hospitalName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: '1.75rem',
    fontWeight: 500,
    color: theme.palette.text.primary,

    [theme.breakpoints.down('md')]: {
      flex: 2,
      textAlign: 'right',
    },
  },
}));

export default withScope(HospitalInfo);
