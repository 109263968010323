import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { dark, light } from './theme';
import colors from './colors';
import { alpha } from '@material-ui/core';
import patients from '../src/assets/images/illustrations/patients.svg';
import patientsLight from '../src/assets/images/illustrations/patients_light.svg';
import procedures from '../src/assets/images/illustrations/procedures.svg';
import proceduresLight from '../src/assets/images/illustrations/procedures_light.svg';
import rooms from '../src/assets/images/illustrations/rooms.svg';
import roomsLight from '../src/assets/images/illustrations/rooms_light.svg';
import users from '../src/assets/images/illustrations/users.svg';
import usersLight from '../src/assets/images/illustrations/users_light.svg';
import waiting from '../src/assets/images/illustrations/waiting.svg';
import waitingLight from '../src/assets/images/illustrations/waiting_light.svg';
import assessments from './assets/images/illustrations/assesments.svg';
import check_in from './assets/images/illustrations/check_in.svg';
import diagnostic from './assets/images/illustrations/diagnostic.svg';
import medications from './assets/images/illustrations/medications.svg';
import nurse_sign_in from './assets/images/illustrations/nurse_sign_in.svg';
import event_light from './assets/images/illustrations/charting/event.svg';
import person_light from './assets/images/illustrations/charting/person.svg';
import assignment_light from './assets/images/illustrations/charting/assignment.svg';
import local_pharmacy_light from './assets/images/illustrations/charting/local_pharmacy.svg';
import playlist_add_check_light from './assets/images/illustrations/charting/playlist_add_check.svg';

const muiPalettes = {
  light: {
    palette: {
      type: 'light',
      primary: {
        main: colors.primary.string(),
        contrastText: '#fff',
      },
      text: {
        primary: colors.text.string(),
        secondary: '#686a7d',
      },
      background: {
        default: '#fff',
      },
    },
  },

  dark: {
    palette: {
      type: 'dark',
      primary: {
        main: colors.primary.string(),
        contrastText: '#fff',
      },
      grey: {
        300: '#1a2553',
        A100: '#1f2c61',
      },
      text: {
        primary: '#fff',
        secondary: 'rgba(255, 255, 255, .5)',
      },
      background: {
        default: dark.backgroundColor.string(),
        paper: '#1a2553',
        secondary: dark.backgroundColor.alpha(0.5).string(),
      },
    },
  },

  print: {
    palette: {
      type: 'light',
      text: {
        primary: '#000',
      },
      background: {
        default: '#fff',
      },
    },
  },
};

const props = {
  MuiButton: {
    disableElevation: true,
  },
  MuiButtonBase: {
    disableRipple: true,
  },
  MuiCheckbox: {
    color: 'primary',
  },
};

const typography = {
  fontFamily: [
    'Rubik',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  h1: {
    fontSize: '2.448rem',
  },
  h2: {
    fontSize: '2.074rem',
    fontWeight: 400,
  },
  h3: {
    fontSize: '1.728rem',
  },
  h4: {
    fontSize: '1.44rem',
    fontWeight: 400,
  },
  h5: {
    fontSize: '1.2rem',
  },
  button: {
    fontSize: '1rem',
    fontWeight: 500,
    textTransform: 'none',
  },
};

const shape = {
  borderRadius: 2,
  borderRadiusLarge: 8,
};

const illustrations = {
  dark: {
    patients: patients,
    users: users,
    rooms: rooms,
    procedures: procedures,
    waiting: waiting,
    event: assessments,
    person: check_in,
    assignment: diagnostic,
    local_pharmacy: medications,
    playlist_add_check: nurse_sign_in,
    search: assessments,
  },
  light: {
    patients: patientsLight,
    users: usersLight,
    rooms: roomsLight,
    procedures: proceduresLight,
    waiting: waitingLight,
    event: event_light,
    person: person_light,
    assignment: assignment_light,
    local_pharmacy: local_pharmacy_light,
    playlist_add_check: playlist_add_check_light,
    search: event_light,
  },
};

export const muiThemeLight = responsiveFontSizes(
  createTheme({
    illustrations: illustrations.light,
    palette: muiPalettes.light.palette,
    props: props,
    typography: typography,
    overrides: {
      MuiExpansionPanel: {
        root: {
          background: light.backgroundColor.string(),
          boxShadow: `0 1px 5px 0px ${light.textColor.alpha(0.2).string()}`,
        },
      },
      MuiTab: {
        root: {
          background: light.panel.background.string(),
          borderTopLeftRadius: '.125em',
          borderTopRightRadius: '.125em',
          padding: '.375em 1.25em',
        },
        textColorInherit: {
          opacity: 0.5,
        },
        wrapped: {
          fontSize: '.85rem',
        },
      },
      MuiCard: {
        root: {
          background: light.panel.background.string(),
        },
      },
    },
    shape: shape,
  })
);

export const muiThemeDark = responsiveFontSizes(
  createTheme({
    illustrations: illustrations.dark,
    palette: muiPalettes.dark.palette,
    props: props,
    typography: typography,
    overrides: {
      MuiBackdrop: {
        root: {
          backgroundColor: alpha(muiPalettes.dark.palette.background.default, 0.9),
        },
      },
      MuiButton: {
        contained: {
          color: '#fff',
        },
      },
      MuiButtonBase: {
        root: {
          textTransform: 'none',
        },
      },
      MuiChip: {
        root: {
          backgroundColor: muiPalettes.dark.palette.background.paper,
          fontWeight: typography.fontWeightMedium,
        },
      },
      MuiCssBaseline: {
        '@global': {
          html: {
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
          },
          '*::-webkit-scrollbar': {
            width: '.5rem',
            height: '.5rem',
          },
          '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'none',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: muiPalettes.dark.palette.grey.A100,
            borderRadius: '.25rem',
            outline: 'none',
          },
        },
      },
      MuiExpansionPanel: {
        root: {
          background: 'transparent',
          borderTop: `1px solid ${dark.border.color.default.string()}`,
          borderBottom: `1px solid ${dark.border.color.default.string()}`,
        },
      },
      MuiListItemIcon: {
        root: {
          opacity: 0.45,
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: dark.button.default.backgroundColor.string(),
        },
      },
      MuiTab: {
        root: {
          background: dark.panel.background.string(),
          borderTopLeftRadius: '.125em',
          borderTopRightRadius: '.125em',
          padding: '.375em 1.25em',
          textTransform: 'none',
        },
        textColorInherit: {
          opacity: 0.5,
        },
        wrapper: {
          lineHeight: 'normal',
        },
      },
      MuiTableCell: {
        root: {
          borderBottom: `1px solid rgba(255, 255, 255, 0.12)`,
        },
      },
      MuiCard: {
        root: {
          background: dark.panel.background.string(),
        },
      },
      PrivateTabIndicator: {
        root: {
          // display: 'none',
        },
      },
    },
    shape: shape,
  })
);

export const muiThemePrint = createTheme({
  palette: muiPalettes.print.palette,
  typography: typography,
});
