import React from 'react';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import toAge from '../../../../../../questionnaire/toAge';
import toHeight from '../../../../../../questionnaire/toHeight';
import toWeight from '../../../../../../questionnaire/toWeight';
import toWeightLbs from '../../../../../../questionnaire/toWeightLbs';
import toBMI from '../../../../../../questionnaire/toBMI';
import get from 'lodash/get';
import { useValue } from '../../ValueContext';
import MedicationTextArea from '../../components/MedicationTextArea';
import styles from '../../styles';

const PatientInfo = () => {
  const { questionnaireAnswers } = useValue();
  let preferred = get(questionnaireAnswers, 'sections.demographicInformation.schema.preferred.option', '') + 'Phone';
  return (
    <div>
      <div style={{ ...styles.fiveColumns2, fontSize: '13px' }}>
        <MedicalPassportField name="procedure.patient.name" label="Patient Name" fontSize={13} />
        <MedicalPassportField name="dob" label="DOB" path={['demographicInformation', 'dob']} fontSize={13} />
        <MedicalPassportField name="age" label="Age" path={['demographicInformation', 'dob']} reducer={toAge} fontSize={13} />
        <MedicalPassportField name="ht" label="HT" path={['demographicInformation', 'bmi']} reducer={toHeight} fontSize={13} />
        <MedicalPassportField name="wt" label="WT" path={['demographicInformation', 'bmi']} reducer={toWeightLbs} fontSize={13} />
      </div>
      <div style={{ ...styles.sixColumns2, fontSize: '12px' }}>
        <MedicalPassportField
          name="primaryPhone"
          label="Primary Phone"
          fontSize={11}
          path={['demographicInformation']}
        />
        <MedicalPassportField
          name="homePhone"
          label="Home"
          fontSize={11}
          path={['demographicInformation', 'HomePhone']}
        />
        <MedicalPassportField
          name="cellPhone"
          label="Cell"
          fontSize={11}
          path={['demographicInformation', 'CellPhone']}
        />
        <MedicalPassportField
          name="workPhone"
          label="Work"
          fontSize={11}
          path={['demographicInformation', 'WorkPhone']}
        />
        <MedicalPassportField
          name="bmi"
          label="BMI"
          fontSize={11}
          path={['demographicInformation', 'bmi']}
          reducer={toBMI}
        />
        <MedicalPassportField
          name="kg"
          label="KG"
          fontSize={11}
          path={['demographicInformation', 'bmi']}
          reducer={toWeight}
        />
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: '0.8fr 1.8fr 2fr 1.5fr',
        borderBottom: 0,
        fontSize: '13px'
      }}>

        <MedicalPassportCheckbox name="rideHome" label="Ride Home" path={['demographicInformation', 'ridehomeInfo']} fontSize={12} />
        <div style={{ fontSize: '12px' }}>
          <MedicationTextArea name="rideHomeInfo" label="" path={['demographicInformation', 'ridehomeInfo', 'yes']} fontSize={12} />
        </div>
        <MedicalPassportField name="procedure.physician.name" label="Physician Name" fontSize={12} />
        <MedicalPassportField name="procedure.serviceTime" label="DOS" fontSize={12} />
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: '10% 90%',
        fontSize: '13px'
      }}>
        Procedure:
        <div style={{ fontSize: '12px' }}><MedicationTextArea name="procedure.name" /></div>
      </div>
    </div>
  );
};

export default PatientInfo;
