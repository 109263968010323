import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { useValue } from '../components/entities/surgeonProcedures/predefinedForms/ValueContext';
import { useQuestionnaireValueViaSelector } from '../components/entities/surgeonProcedures/predefinedForms/hooks';

interface CheckboxProps {
  label?: ReactNode;
  rowspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  name: string;
  questionnaire?: (questionnaire: unknown, questionnaireValue: unknown) => boolean;
  negative?: boolean;
  vertical?: boolean;
  calculated?: () => boolean | null;
  onChanged?: (checked: boolean) => void;
  className?: string;
}

const Checkbox = ({ label, rowspan, colspan, name, questionnaire, negative, vertical, calculated, onChanged, className }: CheckboxProps) => {
  const { defaultValue, value, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValueViaSelector(questionnaire);

  const calculatedValue = (value == null) ? calculated?.() : null;
  
  // use calculated value if one is provided, failover otherwise
  const checked = calculatedValue != null ? calculatedValue : value ?? (questionnaireValue || defaultValue);
  
  return (
    <label
      className={clsx(`f-span-${colspan ?? 12}`, 'checkmark', { 'f-flex-col': vertical }, className)}
      style={{ gridRow: rowspan ? `auto / span ${rowspan}` : undefined }}
    >
      <input
        type="checkbox"
        name={name}
        checked={negative ? checked === false : checked || false} // if checked is nullish, show one as not checked
        onChange={e => {
          const newValue = negative ? e.target.checked === false : e.target.checked;
          setValue(newValue);
          onChanged?.(newValue);
        }}
      />
      <span style={vertical ? {marginBottom: 6} : undefined}></span>
      {label && <span className="f-uppercase">{label}</span>}
    </label>
  );
};

export default Checkbox;
