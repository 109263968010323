import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';
import CustomTooltipPie from './CustomTooltipPie';
import { useTheme } from '@material-ui/core';
import { PieChartData } from '../PieChart';
import { ChartLegend } from './ChartLegend';

const COLORS = ['#2B3C87', '#74AC48', '#B7DD9A', '#A6AFD9', '#6A78B6', '#485796', '#2B3C87'];
const PHYSICIAN_TURNOVER_COLORS = ['#74AC48', '#00A7F7'];
const OR_TURNOVER_COLORS = ['#ff9924'];

export const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return payload.value >= 1 ? (
    <>
      <text
        x={x}
        y={y + 10}
        fill="#fff"
        fontWeight="bold"
        fontSize={14}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        stroke="#1a2553"
        strokeWidth={5}
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        {Math.round(payload.value)}m{' '}
      </text>
      <text
        x={x}
        y={y + 10}
        fill="#fff"
        fontWeight="bold"
        fontSize={14}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {Math.round(payload.value)}m{' '}
      </text>
    </>
  ) : null;
};

export const renderActiveShape = (props, name, subtitle) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={subtitle ? -4 : 0} fontSize="1.2vw" fontWeight={400} textAnchor="middle" fill="white">
        {name}
      </text>
      {subtitle && (
        <text x={cx} y={cy} dy="8%" fontSize=".7vw" fontWeight={500} textAnchor="middle" fill="rgb(125	163	206)">
          {subtitle}
        </text>
      )}
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const ORPerformancePieChart = ({
  data,
  activeIndex,
  name,
  subtitle,
  aspect,
  minWidth,
  isAnimationActive,
  innerRadius,
  outerRadius,
  outerRingInnerRadius,
  outerRingOuterRadius,
  orTurnoverInnerRadius,
  orTurnoverOuterRadius,
  displayLegend = true,
}) => {
  const theme = useTheme();

  const transformedData = data.map(d => {
    return [
      { name: 'Patient Prepping', value: d.inOr },
      { name: 'Procedure', value: d.inSurgery },
      { name: 'Closing', value: d.closing },
      { name: 'Post Closing', value: d.procedureComplete },
      { name: 'Cleaning', value: d.cleaning },
      { name: 'OR Prepping', value: d.orReady },
    ];
  });

  const orTurnover = data.map(d => {
    return [{ name: 'OR Turnover', value: d.turnover || 0 }];
  });

  const turnoverData = data.map(d => {
    return [
      { name: 'In Surgery', value: (d.inSurgery || 0) + (d.closing || 0) },
      {
        name: 'Physician Turnover',
        value: (d.inOr || 0) + (d.procedureComplete || 0) + (d.cleaning || 0) + (d.orReady || 0),
      },
    ];
  });

  const getTotalTime = (d: PieChartData) => {
    return (
      (d.inOr || 0) +
      (d.inSurgery || 0) +
      (d.closing || 0) +
      (d.procedureComplete || 0) +
      (d.cleaning || 0) +
      (d.orReady || 0)
    );
  };

  const surgeryTimeAngleOffset = data.map(d => {
    const total = getTotalTime(d);
    const percentage = Math.round(((d.inOr || 0) / total) * 100);
    return (360 / 100) * percentage;
  });

  const orTurnoverAngleOffset = data.map(d => {
    const total = getTotalTime(d);
    const percentage = Math.round(
      (((d.inOr || 0) + (d.inSurgery || 0) + (d.closing || 0) + (d.procedureComplete || 0)) / total) * 100
    );
    return (360 / 100) * percentage;
  });

  const orTurnoverEndAngleOffset = data.map(d => {
    const total = getTotalTime(d);
    const percentage = Math.round(((d.cleaning || 0) + (d.orReady || 0)) / total);
    return (360 / 100) * percentage;
  });

  return (
    <ResponsiveContainer minWidth={minWidth} width="100%" height="100%" aspect={aspect}>
      <PieChart>
        <Pie
          startAngle={90 - surgeryTimeAngleOffset[activeIndex]}
          endAngle={-270 - surgeryTimeAngleOffset[activeIndex]}
          data={turnoverData[activeIndex]}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={outerRingInnerRadius}
          outerRadius={outerRingOuterRadius}
          paddingAngle={1}
        >
          {transformedData.map((entry, index) => (
            <Cell
              style={{ outline: 'none' }}
              stroke="none"
              key={`cell-${index}`}
              fill={PHYSICIAN_TURNOVER_COLORS[index % PHYSICIAN_TURNOVER_COLORS.length]}
            />
          ))}
        </Pie>

        <Pie
          startAngle={90 - orTurnoverAngleOffset[activeIndex]}
          endAngle={-270 - orTurnoverEndAngleOffset[activeIndex]}
          data={orTurnover[activeIndex]}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={orTurnoverInnerRadius}
          outerRadius={orTurnoverOuterRadius}
          fill="#8884d8"
          paddingAngle={1}
        >
          {transformedData.map((entry, index) => (
            <Cell
              style={{ outline: 'none' }}
              stroke="none"
              key={`cell-${index}`}
              fill={OR_TURNOVER_COLORS[index % OR_TURNOVER_COLORS.length]}
            />
          ))}
        </Pie>

        <Pie
          label={renderCustomizedLabel}
          labelLine={false}
          startAngle={90}
          endAngle={-270}
          maxRadius={200}
          isAnimationActive={isAnimationActive}
          dataKey="value"
          data={transformedData[activeIndex]}
          activeShape={props => renderActiveShape(props, name, subtitle)}
          activeIndex={activeIndex}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          paddingAngle={1}
        >
          {transformedData[activeIndex].map((entry, index) => (
            <Cell
              stroke="none"
              style={{ outline: 'none' }}
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Tooltip
          content={({ active, payload, label }) => <CustomTooltipPie active={active} payload={payload} label={label} />}
          cursor={{ fill: 'rgba(255,255,255,0.15)' }}
          contentStyle={{
            backgroundColor: theme.palette.background.paper,
            borderRadius: 4,
            border: 0,
          }}
        />
        {displayLegend && (
          <Legend content={ChartLegend} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
        )}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ORPerformancePieChart;
