import { Box, Paper, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface AnalyticsPanelProps {
  title?: string;
  children: ReactNode;
}

const AnalyticsPanel = ({ title, children }: AnalyticsPanelProps) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box className={classes.container}>
        {title ? (
          <Typography variant="subtitle2" gutterBottom>
            {title}
          </Typography>
        ) : (
          <Box pt={3} />
        )}
        {children}
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: theme.spacing(2, 1.5),
  },
}));

export default AnalyticsPanel;
