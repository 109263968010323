import React from 'react';
import { useValue } from '../../ValueContext';
import styles from '../../styles';
import MedicalPassportField from '../../components/MedicalPassportField';
import MedicalPassportCheckbox from '../../components/MedicalPassportCheckbox';
import MedicationReconciliationField from '../../components/MedicationReconciliationField';
import GT from '../../../../../../assets/images/GTlogo.png';
import get from 'lodash/get';
import MedicationTextArea from '../../components/MedicationTextArea';
import Barcode from '../../../Barcode';
import { fonts } from 'pdfmake/build/pdfmake';

const Page8Extra = ({ formName, pageNumber, showQRCode, defaultValue, answers }) => {
    const providerId = defaultValue?.procedure?.patient?.providerId;
    const { questionnaireAnswers } = useValue();

    const getMedicationList = answers => {
        const [takesMedication, medicationAnswer] = get(answers, 'sections.medications.yes', []) || [];
        return [takesMedication, get(medicationAnswer, 'list', [])];
    };

    const medicationYes = get(questionnaireAnswers, 'sections.medications.yes', []);
    const medicationList = getMedicationList(questionnaireAnswers);
    const medication = medicationList[1];

    const morethan13 = medication.length > 13;

    let nameArr = [medication.length];
    let doseArr = [medication.length];
    let freqArr = [medication.length];
    let purposeArr = [medication.length];
    let last = [medication.length];

    for (let i = 0; i < medication.length; i++) {
        nameArr[i] = get(medication[i], 'schema.name.text', '');
        doseArr[i] = get(medication[i], 'schema.dosage.text', '');
        if (get(medication[i], 'schema.frequency.option[1].text', '') !== '') {
            freqArr[i] =
                get(medication[i], 'schema.frequency.option[0]', '') +
                ', ' +
                get(medication[i], 'schema.frequency.option[1].text', '');
        } else if (get(medication[i], 'schema.frequency.option[0]', '') === 'Other') {
            freqArr[i] = get(medication[i], 'schema.frequency.option[0]', '');
        } else {
            freqArr[i] = get(medication[i], 'schema.frequency.option', '');
        }
        purposeArr[i] = get(medication[i], 'schema.purpose.text', '');
        last[i] = get(medication[i], 'schema.last.text', '');
    }

    const allergies = get(questionnaireAnswers, 'sections.allergies.schema.q1.checkboxes', {});
    const latex = get(allergies, 'Allergic to Latex?[0]');
    let medicationFlag = false;
    if (medicationYes.length === 0) {
        medicationFlag = true;
    }
    let latexFlag = false;
    if (latex === true) {
        latexFlag = true;
    }

    if (morethan13) {
        return (
            <div id="page8Extra" className="page"  style={{PaddingTop:'0',paddingBottom:'0',minHeight:'1090px'}}>
                <div style={styles.center}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div />
                        <div>
                            {showQRCode && (
                                <Barcode
                                    formName={formName}
                                    pageNumber={pageNumber}
                                    providerId={defaultValue?.procedure?.patient?.providerId}
                                    serviceTime={defaultValue?.procedure?.serviceTime}
                                />
                            )}
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'left', width: '75px', height: '75px' }}>
                        <img src={GT} alt="Hospital Logo" />
                    </div>
                    <strong>
                        <div style={{ display: 'flex', alignItems: 'left', fontSize: '24px' }}>Medication Reconciliation</div>
                    </strong>
                    <strong>
                        <div style={{ display: 'flex', alignItems: 'left', fontSize: '14px' }}>Allergies</div>
                    </strong>
                    <div style={{ display: 'flex', alignItems: 'left', fontSize: '12px' }}>
                        <input type="checkbox" name="page8.latex" label="Latex Allergy / Sensitivity" id="id" checked={latexFlag} />
                        Latex Allergy / Sensitivity
                    </div>
                    <br></br>
                    <div style={{ display: 'flex', alignItems: 'left', fontSize: '12px' }}>
                        <input type="checkbox" name="page8.noMed" label="No Current Medications" id="id2" checked={medicationFlag} />
                        No Current Medications
                    </div>
                    <div style={{ ...styles.sectionHeader, fontWeight: 'bold', padding: '2px', fontSize: '14px' }}>MEDICATION</div>
                    <div
                        style={{
                            lineHeight: '18px',
                            display: 'grid',
                            gridTemplateColumns: '30% 7.5% 15% 15% 12% 6% 7% 7.5%',
                            textAlign: 'center',
                            border: '1px solid black',
                            borderBottom: 0,
                            borderRight: '1px solid black',
                            fontWeight: 'bold',
                        }}
                    >
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Name</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Dose</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Frequency</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Reason</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Last Taken Date & Time</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '10px' }}>Continue</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '10px' }}>Discontinue</div>
                        <div style={{ fontSize: '12px' }}>Date to Resume</div>
                    </div>
                    {[...Array(15)].map((_, i) => (
                        <>
                            <div style={{ ...styles.eightColumns2, lineHeight: '15px' }}>
                                <div style={{ borderRight: '1px solid black', wordBreak: 'break-all' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 13}.r1`}
                                        value={nameArr[i + 13]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 13}.r2`}
                                        value={doseArr[i + 13]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 13}.r3`}
                                        value={freqArr[i + 13]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea
                                        name={`page8.c${i + 13}.r4`}
                                        value={purposeArr[i + 13]}
                                        style={{ fontSize: '10px' }}
                                    />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea name={`page8.c${i + 13}.r5`} style={{ fontSize: '10px' }} />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <div style={{ position: 'relative', left: '30%' }}>
                                        <MedicalPassportCheckbox name={`page8.c${i + 13}.r6`} />
                                    </div>
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <div style={{ position: 'relative', left: '30%' }}>
                                        <MedicalPassportCheckbox name={`page8.c${i + 13}.r7`} />
                                    </div>
                                </div>
                                <div>
                                    <MedicationReconciliationField name={`page8.c${i + 13}.r8`} />
                                </div>
                            </div>
                        </>
                    ))}
                    <div
                        style={{
                            display: 'grid',
                            border: '1px solid black',
                            borderTop: '1px solid black',
                            borderBottom: 0,
                            lineHeight: '1.6em',
                            padding: '0 0.1rem',
                        }}
                    ></div>
                    <div
                        style={{
                            display: 'grid',
                            border: '1px solid black',
                            borderTop: '1px solid black',
                            borderBottom: 0,
                            lineHeight: '1.4em',
                            padding: '0 0.1rem',
                        }}
                    >
                    </div>
                    <div style={{ ...styles.sectionHeader, fontWeight: 'bold', fontSize: '14px' }}>PRESCRIPTIONS GIVEN AT DISCHARGE</div>
                    <div
                        style={{
                            lineHeight: '18px',
                            display: 'grid',
                            gridTemplateColumns: '35% 7.5% 7.5% 12% 18% 20%',
                            textAlign: 'center',
                            border: '1px solid black',
                            borderBottom: 0,
                            borderRight: '1px solid black',
                            fontWeight: 'bold',
                        }}
                    >
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Medication</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Dosage</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Route</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Frequency</div>
                        <div style={{ borderRight: '1px solid black', fontSize: '12px' }}>Indication</div>
                        <div style={{ fontSize: '12px' }}>Last Dose Given</div>
                    </div>

                    {[...Array(4)].map((_, i) => (
                        <>
                            <div style={{ ...styles.sixColumns, lineHeight: '13px' }}>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea name={`page8.pres${i}.c1`} style={{ fontSize: '10px' }} />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea name={`page8.pres${i}.c2`} style={{ fontSize: '10px' }} />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea name={`page8.pres${i}.c3`} style={{ fontSize: '10px' }} />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea name={`page8.pres${i}.c4`} style={{ fontSize: '10px' }} />
                                </div>
                                <div style={{ borderRight: '1px solid black' }}>
                                    <MedicationTextArea name={`page8.pres${i}.c5`} style={{ fontSize: '10px' }} />
                                </div>
                                <div>
                                    <MedicationTextArea name={`page8.pres${i}.c6`} style={{ fontSize: '10px' }} />
                                </div>
                            </div>
                        </>
                    ))}

                    <div style={{ fontSize: '12px' }}>
                        <MedicalPassportCheckbox name="copy" label="Copy given to Patient" />
                    </div>
                    <div style={{ float: 'right' }}>
                        <div style={styles.box}>
                            <div
                                style={{
                                    width: '50%',
                                    height: 'auto',
                                    margin: '0 auto',
                                    padding: '10px',
                                    position: 'relative',
                                    opacity: '.5',
                                }}
                            >
                                PATIENT LABEL
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <strong>
                        <div style={{ textAlign: 'left', fontSize: '14px' }}>Signature Review of Medications Across the Patient Care Continuum</div>
                    </strong>
                    <div style={{ ...styles.signatureBox1, fontSize: '12px' }}>
                        <div>
                            <div style={{ paddingTop: '1.5rem' }}>______________________________</div>
                            <div>Pre-Op Signature</div>
                        </div>
                    </div>
                    <div style={{ ...styles.signatureBox1, fontSize: '12px' }}>
                        <div>
                            <div style={{ paddingTop: '1.5rem' }}>______________________________</div>
                            <div>PACU Signature</div>
                        </div>
                        <div>
                            <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>_______________________________</div>
                            <div>Physician Signature</div>
                        </div>
                    </div>
                    <br></br>
                    <div style={{ fontSize: '12px' }}>Please bring this medication record with you to your physician office/pharmacist</div>
                </div>
            </div>
        );
    }
    else {
        return ''
    };
}


export default Page8Extra;
