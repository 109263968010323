import { gql } from '@apollo/client';

export const fragments = {
  list: gql`
    fragment FormList on Form {
      id
      order
      name
      type
      saveType
      isSigned
      config
      scanChartDefault
    }
  `,
  view: gql`
    fragment FormView on Form {
      id
      order
      name
      type
      saveType
      content
      value
      realValue
      isSigned
      signature
      signedAt
      mainCategory
      defaultValue
    }
  `,
  formBase: gql`
    fragment FormBase on FormSigning {
      id
      order
      name
      formType
      content
      shouldSign
      isSigned
    }
  `,
};

export const procedureForms = gql`
  query procedureForms($procedureId: Long, $formType: FormTypeType) {
    procedureForms(procedureId: $procedureId, formType: $formType) {
      ...FormBase
    }
  }
  ${fragments.formBase}
`;

export const uploadFormTypes = gql`
  query uploadFormTypes {
    uploadFormTypes {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const uploadFormTypesPreOp = gql`
  query uploadFormTypesPreOp {
    uploadFormTypesPreOp {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const uploadFormTypesIntake = gql`
  query uploadFormTypesIntake {
    uploadFormTypesIntake {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const list = gql`
  query forms {
    forms {
      ...FormList
    }
  }
  ${fragments.list}
`;

export const sentToScanChart = gql`
  query sentToScanChart($procedureId: Long!) {
    sentToScanChart(procedureId: $procedureId) {
      id
      path
      procedureId
      formId
      sentAt
      receivedBefore
    }
  }
`;

export const get = gql`
  query form($id: Long!, $procedureId: Long!) {
    form(id: $id, procedureId: $procedureId) {
      ...FormView
      entryQuestionnaire(procedureId: $procedureId) {
        id
        questions
        answers
      }
    }
  }
  ${fragments.view}
`;

export const getSubscription = gql`
  subscription form($id: Long!, $procedureId: Long!) {
    form(id: $id, procedureId: $procedureId) {
      ...FormView
      entryQuestionnaire(procedureId: $procedureId) {
        id
        questions
        answers
        defaultValues
      }
    }
  }
  ${fragments.view}
`;

export const procedureFormSub = gql`
  subscription procedureFormSub($procedureId: Long!, $formType: FormTypeType) {
    procedureFormSub(procedureId: $procedureId, formType: $formType) {
      ...FormBase
    }
  }
  ${fragments.formBase}
`;

export const patientChartForm = gql`
  subscription patientChartForm($id: Long!, $procedureId: Long!, $questionnaireType: String!) {
    patientChartForm(id: $id, procedureId: $procedureId, questionnaireType: $questionnaireType) {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const update = gql`
  mutation submitForm($id: Long!, $procedureId: Long!, $value: Json!, $base: Json) {
    submitForm(id: $id, procedureId: $procedureId, value: $value, base: $base) {
      ...FormView
    }
  }
  ${fragments.view}
`;

export const uploadRequest = gql`
  query uploadRequest($id: Long!, $procedureId: Long, $fileName: String!, $contentType: String!) {
    uploadRequest(id: $id, procedureId: $procedureId, fileName: $fileName, contentType: $contentType)
  }
`;

export const uploadedForms = gql`
  query uploadedForms($id: Long!, $procedureId: Long) {
    uploadedForms(id: $id, procedureId: $procedureId)
  }
`;

export const uploadedFormsSubscription = gql`
  subscription uploadedForms($id: Long!, $procedureId: Long) {
    uploadedForms(id: $id, procedureId: $procedureId)
  }
`;

export const patientFile = gql`
  query patientFile($ids: [Long!]!, $procedureId: Long!, $mdSignature: Boolean!) {
    patientFile(ids: $ids, procedureId: $procedureId, mdSignature: $mdSignature)
  }
`;

export const sendToScanChart = gql`
  mutation sendToScanChart($ids: [Long!]!, $procedureId: Long!) {
    sendToScanChart(ids: $ids, procedureId: $procedureId)
  }
`;

export const bulkPrintForms = gql`
  mutation bulkPrintFormsJob($ids: [Long!]!, $procedureIds: [Long!]!) {
    bulkPrintForms(ids: $ids, procedureIds: $procedureIds) {
      id
      status
      itemStatuses
    }
  }
`;

export const bulkPrintFormsJob = gql`
  subscription bulkPrintFormsJob($bulkPrintJobId: Long!) {
    bulkPrintFormsJob(bulkPrintJobId: $bulkPrintJobId) {
      id
      status
      itemStatuses
    }
  }
`;

export const confirmFormUpload = gql`
  mutation confirmFormUpload($id: Long!, $procedureId: Long, $fileName: String!, $value: Json!) {
    confirmFormUpload(id: $id, procedureId: $procedureId, fileName: $fileName, value: $value)
  }
`;

export const removeUploadedForm = gql`
  mutation removeUploadedForm($id: Long!, $procedureId: Long, $fileName: String!, $value: Json!) {
    removeUploadedForm(id: $id, procedureId: $procedureId, fileName: $fileName, value: $value)
  }
`;

export default {
  list,
  get,
  update,
  getSubscription,
  uploadRequest,
  confirmFormUpload,
  procedureFormSub,
  uploadedForms,
  uploadedFormsSubscription,
  uploadFormTypes,
  removeUploadedForm,
  patientFile,
  uploadFormTypesPreOp,
  uploadFormTypesIntake,
};
