import { gql } from '@apollo/client';

export const fragments = {
  all: gql`
    fragment ScreenData on Screen {
      id
      code
      connection {
        type {
          type
          room {
            id
            name
          }
        }
        active
        clientVersion
        lastDisconnectedAt
      }
      configuration {
        description
        showProcedure
        patientNameFormat
        physicianNameFormat
        dischargeSound
        orReadySound
        waitingReadySound
        pacuReadySound
        prepReadySound
        readyForSurgeonSound
        readyToPickupSound
        holdProcedureSound
        blockNerveSound
        closingSound
        helpSound
        sendNotifications
        preOpFocused
        pacuFocused
        isTimeline
        limit
        minimumRating
        showBedNumber
        showStaffListOnSchedule
        useCssGridLayout
        showPostOp
        chartingPinTimeout
        questionnaireSessionTimeout
        showRideHome
        showPreOpWr
        showIntakeWr
        showOrRooms
        lightMode
        anesthesiologistSchedule
        messagingScreen
        showDatePickerOnSchedule
        showPatientTypes
      }
    }
  `,
};

export const list = gql`
  subscription {
    screens {
      ...ScreenData
    }
  }
  ${fragments.all}
`;

export const get = gql`
  query screen($screenCode: String!) {
    screen(screenCode: $screenCode) {
      ...ScreenData
    }
  }
  ${fragments.all}
`;

export const screenConfiguration = gql`
  subscription {
    screenConfiguration {
      description
      showProcedure
      patientNameFormat
      physicianNameFormat
      dischargeSound
      orReadySound
      waitingReadySound
      pacuReadySound
      prepReadySound
      readyForSurgeonSound
      readyToPickupSound
      holdProcedureSound
      blockNerveSound
      closingSound
      helpSound
      sendNotifications
      preOpFocused
      pacuFocused
      isTimeline
      showBedNumber
      showStaffListOnSchedule
      useCssGridLayout
      showPostOp
      chartingPinTimeout
      questionnaireSessionTimeout
      showRideHome
      showPreOpWr
      showIntakeWr
      showOrRooms
      lightMode
      anesthesiologistSchedule
      messagingScreen
      showDatePickerOnSchedule
      showPatientTypes
    }
  }
`;

export const approveScreen = gql`
  mutation approveScreen($screenCode: String!, $screenType: IScreenType!) {
    approveScreen(screenCode: $screenCode, screenType: $screenType)
  }
`;

export const removeScreen = gql`
  mutation removeScreen($screenCode: String!) {
    removeScreen(screenCode: $screenCode)
  }
`;

export const updateScreen = gql`
  mutation updateScreen(
    $screenCode: String!
    $description: String
    $showProcedure: Boolean
    $patientNameFormat: PatientNameFormat
    $physicianNameFormat: PhysicianNameFormat
    $dischargeSound: Boolean
    $orReadySound: Boolean
    $waitingReadySound: Boolean
    $pacuReadySound: Boolean
    $prepReadySound: Boolean
    $readyForSurgeonSound: Boolean
    $readyToPickupSound: Boolean
    $holdProcedureSound: Boolean
    $blockNerveSound: Boolean
    $closingSound: Boolean
    $helpSound: Boolean
    $sendNotifications: Boolean
    $preOpFocused: Boolean
    $pacuFocused: Boolean
    $isTimeline: Boolean
    $limit: Int
    $minimumRating: Int
    $showBedNumber: Boolean
    $showStaffListOnSchedule: Boolean
    $useCssGridLayout: Boolean
    $showPostOp: Boolean
    $chartingPinTimeout: Int
    $questionnaireSessionTimeout: Int
    $showRideHome: Boolean
    $showPreOpWr: Boolean
    $showIntakeWr: Boolean
    $showOrRooms: [Long!]
    $lightMode: Boolean
    $anesthesiologistSchedule: Boolean
    $messagingScreen: Boolean
    $showDatePickerOnSchedule: Boolean
    $showPatientTypes: [String!]
  ) {
    updateScreen(
      screenCode: $screenCode
      description: $description
      showProcedure: $showProcedure
      patientNameFormat: $patientNameFormat
      physicianNameFormat: $physicianNameFormat
      dischargeSound: $dischargeSound
      orReadySound: $orReadySound
      waitingReadySound: $waitingReadySound
      pacuReadySound: $pacuReadySound
      prepReadySound: $prepReadySound
      readyForSurgeonSound: $readyForSurgeonSound
      readyToPickupSound: $readyToPickupSound
      holdProcedureSound: $holdProcedureSound
      blockNerveSound: $blockNerveSound
      closingSound: $closingSound
      helpSound: $helpSound
      sendNotifications: $sendNotifications
      preOpFocused: $preOpFocused
      pacuFocused: $pacuFocused
      isTimeline: $isTimeline
      limit: $limit
      minimumRating: $minimumRating
      showBedNumber: $showBedNumber
      showStaffListOnSchedule: $showStaffListOnSchedule
      useCssGridLayout: $useCssGridLayout
      showPostOp: $showPostOp
      chartingPinTimeout: $chartingPinTimeout
      questionnaireSessionTimeout: $questionnaireSessionTimeout
      showRideHome: $showRideHome
      showPreOpWr: $showPreOpWr
      showIntakeWr: $showIntakeWr
      showOrRooms: $showOrRooms
      lightMode: $lightMode
      anesthesiologistSchedule: $anesthesiologistSchedule
      messagingScreen: $messagingScreen
      showDatePickerOnSchedule: $showDatePickerOnSchedule
      showPatientTypes: $showPatientTypes
    )
  }
`;

export default {
  list,
};
