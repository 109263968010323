import React, { Fragment, useState } from 'react';
import Form from '../Form';
import Section from '../Section';
import Field from '../Field';
import Cell, { CellProps, LabeledCell, LabeledCellProps } from '../Cell';
import Checkbox from '../Checkbox';
import {
  selectAge,
  selectBMI,
  selectBoolean,
  selectBooleanText,
  selectHeight,
  selectHighlightedString,
  selectHighlightedString2,
  selectList,
  selectString,
  selectStringWithPrefix,
  selectWeight,
  selectWeightLbs,
} from '../selectors';
import Spacer from '../Spacer';
import List from '../List';
import { Allergy } from './allergy';

import {
  airway,
  cardiovascular,
  pulmonary,
  renal,
  liver,
  neurological,
  gastrointestinal,
  hematological,
  endocrine,
  musculoskeletal,
  psychiatric,
  ob,
  infectious,
  derm,
  substance,
  assist,
  other,
} from '../../../src/components/entities/surgeonProcedures/predefinedForms/polaris/paths';
import { useAllergies } from './hooks/useAllergies';
import { get } from 'lodash';
import { useValue } from '../../../src/components/entities/surgeonProcedures/predefinedForms/ValueContext';
import { Medication, useMedications } from './hooks/useMedications';
import { Surgery, useSurgeries } from './hooks/useSurgeries';


export const medicalPassport8n = () => (
  <>
    <Form>
      <Section title="Patient Info" outlined>
        <Field
          label="Name"
          colspan={6}
          name="procedure.patient.name"
          questionnaire={selectString('demographicInformation.name')}
        />
        <Field
          label="Maiden Name"
          colspan={6}
          name="patientMaidenName"
          questionnaire={selectString('demographicInformation.maidenName')}
        />
        <Field
          label="Gender"
          colspan={3}
          name="gender"
          questionnaire={selectHighlightedString('demographicInformation.sex')}
        />
        <Field
          label="Age"
          colspan={3}
          name="age"
          questionnaire={selectAge('demographicInformation.dob')}
        />
        <Field
          label="DoB"
          colspan={3}
          name="dob"
          questionnaire={selectString('demographicInformation.dob')}
        />
        <Field
          label="SSN"
          colspan={3}
          name="ssn"
          questionnaire={selectString('demographicInformation.ssn')}
        />
        <Field label="E-mail" colspan={6} name="email" questionnaire={selectString('demographicInformation.email')} />
        < PrimaryPhoneField label="Preferred #" colspan={6} name="preferred"/>
        <Field
          label="Cell #"
          colspan={4}
          name="cellPhone"
          questionnaire={selectString('demographicInformation.CellPhone')}
        />
        <Field
          label="Home #"
          colspan={4}
          name="homePhone"
          questionnaire={selectString('demographicInformation.HomePhone')}
        />
        <Field
          label="Work #"
          colspan={4}
          name="workPhone"
          questionnaire={selectString('demographicInformation.WorkPhone')}
        />
        <AddressCell label="ADDRESS #:" />
        <Field
          label="Marital Status"
          colspan={4}
          name="maritalStatus"
          questionnaire={selectHighlightedString('demographicInformation.MaritalStatus')}
        />
        <Field
          label="Spouse / Significant Other Name"
          colspan={8}
          name="spouse"
          questionnaire={selectString('demographicInformation.spouse')}
        />
        <Field label="Race" colspan={4} name="race" questionnaire={selectString('demographicInformation.race')} />
        <Field
          label="Ethnicity"
          colspan={4}
          name="ethnicity"
          questionnaire={selectString('demographicInformation.ethnicity')}
        />
        <Field
          label="Religion"
          colspan={4}
          name="religion"
          questionnaire={selectHighlightedString('demographicInformation.religion')}
        />
        <Field
          label="Primary Language"
          colspan={6}
          name="primaryLanguage"
          questionnaire={selectHighlightedString('demographicInformation.primaryLanguage')}
        />
        <Checkbox
          label="Requires Interpreter"
          colspan={6}
          name="requiresInterpreter"
          questionnaire={selectBoolean('demographicInformation.interpreter')}
        />
        <Checkbox
          label="Advance Directive"
          name="advanceDirective"
          questionnaire={selectBoolean('demographicInformation.AdvanceDirective')}
        />
        <Checkbox
          label="May we leave message on home phone"
          colspan={6}
          name="leaveMessageOnHomePhone"
          questionnaire={selectBoolean('demographicInformation.consent2.yes.consent2_home')}
        />
        <Checkbox
          label="May we e-mail a message"
          colspan={6}
          name="leaveMessageOnEmail"
          questionnaire={selectBoolean('demographicInformation.consent2.yes.consent2_email')}
        />
        <Checkbox
          label="May we leave message with another person"
          colspan={6}
          name="leaveMessageToAnotherPerson"
          questionnaire={selectBoolean('demographicInformation.consent2.yes.consent2_person')}
        />
        <Field
          label="If yes, their name"
          colspan={6}
          name="leaveMessageToAnotherPersonName"
          questionnaire={selectString('demographicInformation.consent2.yes.consent2_person.yes')}
        />
      </Section>
      <Section title="Emergency Contact Info" outlined>
        <Field
          label="Name"
          colspan={6}
          name="emergencyInfo.name"
          questionnaire={selectString('demographicInformation.emergencyInfo.yes.emergency_name')}
        />
        <Field
          label="Relationship"
          colspan={6}
          name="emergencyInfo.relationship"
          questionnaire={selectString('demographicInformation.emergencyInfo.yes.emergency_relationship')}
        />
        <Field
          label="Phone"
          colspan={12}
          name="emergencyInfo.phone"
          questionnaire={selectString('demographicInformation.emergencyInfo.yes.emergency_phone')}
        />
      </Section>
      <Section title="Employment Info" outlined>
        <Cell colspan={3}>EMPLOYMENT STATUS:</Cell>
        <Cell colspan={3}>{employmentStatus()}</Cell>

        <LabeledCell label="OCCUPATION:" colspan={6} children={selectRaw('sections.demographicInformation.schema.employment_info.option[1].schema.employment_occupation.text')} />
        <LabeledCell label="COMPANY:" colspan={6} children={selectRaw('sections.demographicInformation.schema.employment_info.option[1].schema.employment_company.text')} />
        <LabeledCell label="PHONE:" colspan={6} children={selectRaw('sections.demographicInformation.schema.employment_info.option[1].schema.employment_phone.text')} />

        <EmploymentAddressCell label="ADDRESS:"/>
      </Section>
      <Section title="Insurance" outlined>
        <Field label="Procedure" colspan={12} vertical name="procedure.name" />
      </Section>
      <Spacer />
      <FooterSection />
    </Form>
    <Form title="POLICIES ACKNOWLEDGEMENT">
      <br/>
      <HeaderSection />
      <br/>
      <br/>
      <Section title="Pre-Visit Instructions" outlined>
        <Cell colspan={3}>NPO</Cell>
        <Checkbox label="Yes" colspan={3} name="npo" calculated={hasPolicy} />
        <Cell colspan={3}>No Shaving</Cell>
        <Checkbox label="Yes" colspan={3} name="shaving" calculated={hasPolicy} />
        <Cell colspan={3}>Delay for arrival</Cell>
        <Checkbox label="Yes" colspan={3} name="arrival" calculated={hasPolicy} />
        <Cell colspan={3}>Leave valuables at home</Cell>
        <Checkbox label="Yes" colspan={3} name="leave" calculated={hasPolicy} />
        <Cell colspan={3}>Bring photo ID & insurance</Cell>
        <Checkbox label="Yes" colspan={3} name="ID" calculated={hasPolicy} />
        <Cell colspan={3}>Advance Directive</Cell>
        <Checkbox label="Yes" colspan={3} name="advance" calculated={hasPolicy} />
        <Cell colspan={3}>Shower/Brush teeth</Cell>
        <Checkbox label="Yes" colspan={3} name="shower" calculated={hasPolicy} />
        <Cell colspan={3}>Clothing</Cell>
        <Checkbox label="Yes" colspan={3} name="clothing" calculated={hasPolicy} />
        <Cell colspan={3}>No make-up / perfume / lotion / nail polish</Cell>
        <Checkbox label="Yes" colspan={3} name="makeup" calculated={hasPolicy} />
        <Cell colspan={3}>No Jewelry</Cell>
        <Checkbox label="Yes" colspan={3} name="jewelry" calculated={hasPolicy} />
        <Cell colspan={3}>Glasses/dentures</Cell>
        <Checkbox label="Yes" colspan={3} name="glasses" calculated={hasPolicy} />
        <Cell colspan={3}>Take prescribed meds</Cell>
        <Checkbox label="Yes" colspan={3} name="meds" calculated={hasPolicy} />
        <Cell colspan={3}>Bring list of meds</Cell>
        <Checkbox label="Yes" colspan={3} name="list" calculated={hasPolicy} />
        <Cell colspan={3}>Ride home</Cell>
        <Checkbox label="Yes" colspan={3} name="ride" calculated={hasPolicy} />
        <Cell colspan={3}>Arrival time</Cell>
        <Checkbox label="Yes" colspan={3} name="arrivalT" calculated={hasPolicy} />
        <Cell colspan={3}>Questions / concerns</Cell>
        <Checkbox label="Yes" colspan={3} name="question" calculated={hasPolicy} />
      </Section>
      <Section title="Discharge Screening" outlined>
        <Cell colspan={3}>Ride home</Cell>
        <Checkbox
          label="Yes"
          colspan={3}
          name="rideHome"
          questionnaire={selectBoolean('demographicInformation.ridehomeInfo')}
        />
        <Cell rowspan={3} colspan={6} />
        <Cell colspan={3}>Driver name</Cell>
        <Field colspan={3} name="rideHomeInfo" questionnaire={selectString('demographicInformation.ridehomeInfo.yes.name')} />
        <Cell colspan={3}>Driver cell number</Cell>
        <Field colspan={3} name="rideHomeInfoContact" questionnaire={selectString('demographicInformation.ridehomeInfo.yes.phone')} />
      </Section>
      <Spacer />
      <FooterSection />
    </Form>
    <Form title="MEDICATION RECONCILIATION">
      <HeaderSection />
      <div className={'dense dense-comfy'}>
      <br/>
      <h1 style={{textAlign: 'center'}}>Medications Prior to Admission</h1>
      <p>
        List all medications, vitamins, nutritional and/or herbal supplements, including medications administrated via
        pump, or patch, prior to admission.
      </p>      
      <MedicationReconciliationList />
      </div>

      <br/>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div>Pre-Op Nurse Signature: ________________________</div>
        <div>Date: _________________</div>
      </div>

      <br/>
      <div className={'dense dense-comfy'}>
      <Section outlined>
        <Cell colspan={6}>Prescriptions Given at Discharge</Cell>
        <Cell colspan={6}>Next Dose</Cell>
        <List name="prescription">
          {length => (
            <>
              {[...Array(Math.max(length, 3))].map((_, i) => (
                <Fragment key={i}>
                  <Field colspan={6} name={`prescription[${i}].medication`} />
                  <Field colspan={6} name={`prescription[${i}].nextDose`} />
                </Fragment>
              ))}
            </>
          )}
        </List>
      </Section>
      </div>

      <Checkbox label="Discharge Medications reviewed with patient or patient representative." name="discharge" />

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div>Post-Op Nurse Signature: ________________________</div>
        <div>Date/Time: _________________</div>
        <div>Patient or Responsible Party Signature: ________________________</div>
        <div>Date/Time: _________________</div>
      </div>
      <Spacer />
      <FooterSection />
    </Form>
    <Form title="ALLERGY RECORD">
      <HeaderSection />
      <br/>
      <AllergyList />
      <br/>
      <Cell colspan={12}>Pre-Op Nurse Acknowledgement: _________________________________</Cell>
      <br/>
      <Cell colspan={12}>Circulating Nurse Acknowledgement: _________________________________</Cell>
      <br/>
      <Cell colspan={12}>PACU Nurse Acknowledgement: _________________________________</Cell>
      <br/>
      <Cell colspan={12}>Relief RN: _________________________________</Cell>
      <br/>
      <Cell colspan={12}>Relief RN: _________________________________</Cell>
      <br/>
      <Cell colspan={12}>Relief RN: _________________________________</Cell>
      <br/>
      <Cell colspan={12}>Relief RN: _________________________________</Cell>
    </Form>
    <Form>
      <HeaderSection2 />


      <br />
      <Section>
        <Cell colspan={4}>Previous Surgical History</Cell>
        <Cell colspan={4}>Current Medications</Cell>
        <Cell colspan={4}>Allergies/Sensitivities</Cell>

        <Field colspan={4} vertical={true} questionnaire={selectHighlightedString('priorSurgeries')} />
        <Field colspan={4} vertical={true} questionnaire={selectHighlightedString('medications')}/>
        <AllergiesCell colspan={4} />
      </Section>
      <br/>
      <Section outlined={true}>
        <Cell colspan={6}>Family History/SurgicalProblems</Cell>
        <Field colspan={6} label={'Social History - Alcohol'} questionnaire={selectString('social.drinking.yes')} />

        <Field colspan={6} label={'PONV'} name="page5.PONV"/>
        <Field colspan={6} rowspan={3} label={'Social Drugs'} questionnaire={selectString('social.drugs')}/>

        <Cell colspan={6}>Family/Self problems with anesthesia</Cell>
        <Field
          colspan={6}
          questionnaire={selectString('PreProcedureAssessment.associatedProblem.yes.associatedProblem_text2')}
        />
        <Field colspan={6} questionnaire={selectString('PreProcedureAssessment.associatedProblem.yes.associatedProblem_text')}/>

        <Field colspan={6} label={'Cigarettes'} questionnaire={selectHighlightedString('social.smoking')}/>
      </Section>
      <br />
      <div className={'dense dense-comfy'}>
      <Section title={'Review of Systems'} outlined={true}>
        <ReviewCell colspanTitle={2} colspanDesc={10} title='1. Airway' paths={airway} insideSection/>
      </Section>
      <ReviewCell colspanTitle={2} colspanDesc={10} title='2. Pulmonary' paths={pulmonary} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='3. Cardiovascular' paths={cardiovascular} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='4. Gastrointestinal' paths={gastrointestinal} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='5. Renal' paths={renal} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='6. Endocrine' paths={endocrine} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='7. Liver' paths={liver} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='8. Neurological' paths={neurological} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='9. Musculo-skeletal' paths={musculoskeletal} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='10. Hematological' paths={hematological} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='11. Psychiatric' paths={psychiatric} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='12. Othe' paths={other} />
      <ReviewCell colspanTitle={2} colspanDesc={10} title='13. OB/Gyn' paths={ob} />
      </div>
      <Spacer />
      <FooterSection />
    </Form>
    <Form title="Polaris Surgery Center (page 6)">
      <br />
      <HeaderSection2 />
      <br />
      <Section outlined>
        <Cell>
          <b>H&P</b>
        </Cell>
        <Cell colspan={4}>
          <b>Neuro</b>
        </Cell>
        <Cell colspan={4}>
          <b>Cardiac</b>
        </Cell>
        <Cell colspan={4}>
          <b>Pulmonary</b>
        </Cell>

        <Checkbox label="Oriented" colspan={4} rowspan={3} name="page6.oriented" />
        <Checkbox label="Normal Rhythm" colspan={4} rowspan={2} name="page6.normalRhythm" />
        <Checkbox label="Clear to Auscultation Wheezes/Rhonchi/Rales" colspan={4} rowspan={3} name="page6.clear" />

        <Checkbox label="No Murmurs" colspan={4} name="page6.noMurmurs" />
      </Section>

      <br />
      <br />
      <p>
        ___________________________ Physician Signature ___________/______/__________ Date ___________________________{' '}
      </p>
      <br />
      <Section>
        <Cell colspan={12}>
          <b>PRE-SURGICAL ASSESSMENT/HISTORY AND PHYSICAL UPDATE</b>
        </Cell>
        <Checkbox
          label="Pre-Surgical Assessment Completed upon admission. No changes noted. Patient is cleared for surgery in an ambulatory setting."
          colspan={12}
          name="page6.preSurgicalNoChanges"
        />
        <Checkbox
          label="Pre-Surgical Assessment Completed upon admission. Changes are noted above."
          colspan={12}
          name="page6.preSuricalChanges"
        />
      </Section>
      <br />
      <p>Provider Signature _________________________________ Date ________________ Time_______________</p>
      <Spacer />
      <FooterSection />
    </Form>
    <Form title="Pre Operative Anesthesia Assessment (page 7)">
      <HeaderSection2 />
      <br />

      <Cell colspan={12}>Printed data provided through online survey:</Cell>

      <Section outlined dense>
        <Cell colspan={8}>
          <b>History of Anesthesia Complications</b>
        </Cell>
        <Cell colspan={4}>
          <b>Allergies</b>
        </Cell>

        <Field
          colspan={8}
          label="Patient"
          questionnaire={selectString('PreProcedureAssessment.associatedProblem.yes.associatedProblem_text2')}
        />
        <AllergiesCell colspan={4} rowspan={2} />

        <Field
          colspan={8}
          label="Family Member"
          questionnaire={selectString('PreProcedureAssessment.associatedProblem.yes.associatedProblem_text')}
        />
      </Section>

      <Section outlined dense>
        <Cell colspan={8}>
          <b>Review of Systems</b>
        </Cell>
        <Cell colspan={4}>
          <b>Current Medications</b>
        </Cell>
        <Cell colspan={2}>Cardiovascular</Cell>
        <Field
          colspan={6}
          questionnaire={selectString(...cardiovascular.map(([category, value]) => `${category}.${value}`))}
        />

        <Cell colspan={4} rowspan={3}>
          <Field questionnaire={selectHighlightedString('medications')} />
        </Cell>

        <Cell colspan={2}>Pulmonary</Cell>
        <Field
          colspan={6}
          questionnaire={selectString(...pulmonary.map(([category, value]) => `${category}.${value}`))}
        />

        <Cell colspan={2}>Renal</Cell>
        <Field colspan={6} questionnaire={selectString(...renal.map(([category, value]) => `${category}.${value}`))} />
      </Section>

      <Section outlined dense>
        <Cell colspan={2}>Hepatic</Cell>
        <Field colspan={6} questionnaire={selectString(...liver.map(([category, value]) => `${category}.${value}`))} />

        <Cell colspan={4}>
          <b>Previous Surgical History</b>
        </Cell>
      </Section>

      <Section outlined dense>
        <ReviewCell insideSection colspanTitle={2} colspanDesc={6} title="Neurological" paths={neurological} rightmostCell={ {colspan: 4, rowspan: 2, children: <SurgeriesCell />} }/>
        <ReviewCell insideSection colspanTitle={2} colspanDesc={6} title="Gastrointestinal" paths={gastrointestinal} />
      </Section>

        <ReviewCell colspanTitle={2} colspanDesc={6} title="Hematological" paths={hematological} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="Endocrine / Metabolic" paths={endocrine} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="Musculo-skeletal" paths={musculoskeletal} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="Psychiatric" paths={psychiatric} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="OB/Gyn" paths={ob} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="Coronavirus" paths={infectious} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="ENT / Dermatological" paths={derm} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="Substance Use" paths={substance} />
        <ReviewCell colspanTitle={2} colspanDesc={6} title="Other MRSA, glasses, contacts, assistive devices" paths={assist} />
      <br />
      <Section outlined>
        <Cell>
          <b>Physical Exam / Labs / Anesthesia Plan</b>
        </Cell>
        <Cell colspan={3}>
          <strong>Neuro</strong>
        </Cell>
        <Cell colspan={3}>
          <b>Cardiac</b>
        </Cell>
        <Cell colspan={3}>
          <b>Pulmonary</b>
        </Cell>
        <Cell colspan={3}>
          <b>Airway</b>
        </Cell>

        <Checkbox label="Oriented" colspan={3} rowspan={6} name="page7.oriented" />
        <Checkbox label="Normal Rhythm" colspan={3} rowspan={5} name="page7.normalRhythm" />
        <Checkbox label="Clear to Auscultation Wheezes/Rhonchi/Rales" colspan={3} rowspan={5} name="page7.clear" />
        <Field label="Class" colspan={3} name='page7.Class' />

        <Cell colspan={1}>Oral Opening</Cell>
        <Checkbox label="Normal" colspan={2} name='page7.NormalAir' />

        <Cell colspan={1} rowspan={3}>Dentition</Cell>
        <Checkbox label="Normal" colspan={2} name='page7.normalDentition' />
        <Checkbox label="Dentures" colspan={2} name='page7.dentures' />
        <Checkbox label="Chipped / Broken" colspan={2} name='page7.chipped' />

        <Checkbox label="No Murmurs" colspan={3} name="page7.noMurmurs" />
        <Cell colspan={3}></Cell>
        <Cell colspan={3}></Cell>
      </Section>

      <Section outlined>
        <Cell colspan={3} rowspan={4}>
          <strong>
            <u>EKG</u>
          </strong>
        </Cell>
        <Cell colspan={9}>
          <b>Labs</b>
        </Cell>

        <Field label="Na" colspan={3} name='page7.Na' />
        <Field label="Hgb" colspan={3} name='page7.Hgb' />
        <Field label="Accucheck" colspan={3} name='page7.Accucheck' />

        <Field label="K" colspan={3} name='page7.k' />
        <Field label="Hct" colspan={6} name='page7.Hct' />

        <Field label="Cr" colspan={3} name='page7.Cr' />
        <Field label="PLT" colspan={6} name='page7.PLT' />
      </Section>

    </Form>
    <Form title="Pre Operative Anesthesia Assessment (page 8)">
      <HeaderSection2 />

      <br />
      <Section title="Comments" outlined>
        <Field name="comments" />
      </Section>

      <Section outlined>
        <Cell colspan={1}><b>ASA</b></Cell>
        <Checkbox colspan={1} name="page8.asa1" label="1" />
        <Checkbox colspan={1} name="page8.asa2" label="2" />
        <Checkbox colspan={1} name="page8.asa3" label="3" />
        <Checkbox colspan={1} name="page8.asa4" label="4" />

        <Cell colspan={2}>Assessment:</Cell>
        <Checkbox colspan={5} name="page8.noContraindications" label="No contraindications to anesthesia" />

        <Cell colspan={5}></Cell>

        <Cell colspan={2}></Cell>
        <Checkbox colspan={2} name="page8.planOther1" label="Other" />
        <Field colspan={3} name="page8.planOtherField1" />

        <Cell colspan={3}>*NPO Status Verified?</Cell>
        <Checkbox colspan={1} name="page8.NPOYes" label="Yes" />
        <Checkbox colspan={1} name="page8.NPONo" label="No" />

        <Cell colspan={2}>Anesthetic Plan:</Cell>
        <Checkbox colspan={1} name="page8.planGA" label="GA" />
        <Checkbox colspan={1} name="page8.planMAC" label="MAC" />
        <Checkbox colspan={3} name="page8.planRegionalBlock" label="Regional Block" />

        <Cell colspan={3}>*Pt. Reassessed Day of Surgery?</Cell>
        <Checkbox colspan={1} name="page8.PTYes" label="Yes" />
        <Checkbox colspan={1} name="page8.PTNo" label="No" />

        <Cell colspan={2}></Cell>
        <Checkbox colspan={2} name="page8.planOther2" label="Other" />
        <Field colspan={3} name="page8.planOtherField2" />
      </Section>

      <br/>
      <Section>
        <Cell colspan={2}><strong>Informed consent obtained.</strong></Cell>
        <Checkbox colspan={10} name="page8.consent" label="I have discussed the risks, benefits and alternatives of opiods/nonopioids and the planned anesthesia with the patient and/or family" />

        <FreeEntryLine title='Anesthesiologist'/>
        <FreeEntryLine title='CRNA'/>
        <FreeEntryLine title='Date'/>
        <FreeEntryLine title='Time'/>

      </Section>
    </Form>
    <Form title="HISTORY & PHYSICAL (page 9)">
      <HeaderSection />
      <br/>
      <div className={'dense'}>
      <Section title="Patient Snapshot" outlined>
        <Cell colspan={4}>Gender</Cell>
        <Field colspan={2} questionnaire={selectHighlightedString('demographicInformation.sex')}/>
        <Cell colspan={4}>Has allergies</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('allergies.q1')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Angina</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.angina')}/>
        <Cell colspan={4}>Asthma</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Pulmonary.Asthma')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Smoking</Cell>
        <Field colspan={2} questionnaire={selectHighlightedString('social.smoking')}/>
        <Cell colspan={4}>Dermatology Assessment</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.dermatology.dermatology_q1')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Snore</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('sleepApneaQ.SleepApneaQ_q1')}/>
        <Cell colspan={4}>Latex allergy</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('allergies.q2')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>MI</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.HeartAttack')}/>
        <Cell colspan={4}>Emphysema or COPD</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Pulmonary.COPD')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Alcohol</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('social.drinking')}/>
        <Cell colspan={4}>Body piercings</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.dermatology.dermatology_q2')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Lab work done</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreVisitRequirements.labworkDone')}/>
        <Cell colspan={4}>Motion sickness</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment["Motion sickness"]')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Internal nerve simulator</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.InternalNerveStimulator')}/>
        <Cell colspan={4}>Loose teeth</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.teethProblem')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Pacemaker</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.Pacemaker')}/>
        <Cell colspan={4}>Eye conditions</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.EyesEarsNoseThroat.EyesEarsNoseThroat_q1')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Contact Lenses</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.contactLenses')}/>
        <Cell colspan={4}>AICD</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.defibrillator')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Hearing impairment/aid</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.hearingproblem')}/>
        <Cell colspan={4}>High cholesterol</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.HighCholesterol')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Diabetes</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.endocrine.endocrine_q1')}/>
        <Cell colspan={4}>Assistive Devices</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.assistiveDevice')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>CAD</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.CAD')}/>
        <Cell colspan={4}>Thyroid disease</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.endocrine.endocrine_q2')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Special assistance</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.specialAssistance')}/>
        <Cell colspan={4}>Vascular disease</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.VascularDisease')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>COVID-19 exposure</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.contactwith1')}/>
        <Cell colspan={4}>Heart valve problems</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.HeartValve')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>HIV/AIDS</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.liver.liver_q1')}/>
        <Cell colspan={4}>Liver disease</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.liver.liver_q2')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Env. allergies</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('allergies.q3')}/>
        <Cell colspan={4}>Hypertension</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.HighBloodPressure')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Sleep Apnea</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Pulmonary.SleepApnea')}/>
        <Cell colspan={4}>Recreational Drugs</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('social.drugs')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Pre-Op EKG</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreVisitRequirements.scheduledEKG')}/>
        <Cell colspan={4}>Stop breathing</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('sleepApneaQ.SleepApneaQ_q3')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Hepatitis</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.liver.liver_q3')}/>
        <Cell colspan={4}>Incontinence</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.kidneyUrinary.kidneyUrinary_q1')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Kidney stones</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.kidneyUrinary.kidneyUrinary_q3')}/>
        <Cell colspan={4}>Heartburn</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.gastrointestinal.gastrointestinal_q1')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Ulcers</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.gastrointestinal.gastrointestinal_q2')}/>
        <Cell colspan={4}>Digestive Problems</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.gastrointestinal.gastrointestinal_q3')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Prescriptions</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medications.medicationList')}/>
        <Cell colspan={4}>Low Blood Pressure</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.LowBloodPressure')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Cold/flu/illness symptoms</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Pulmonary.symptoms')}/>
        <Cell colspan={4}>Chest X-ray?</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreVisitRequirements.Xray')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>High blood pressure</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.HighBloodPressure')}/>
         <Cell colspan={4}>Has muscular / skeletal conditions</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q1')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Chronic LBP</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q2')}/>
        <Cell colspan={4}>Arthritis</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q3')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Myopathy</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q4')}/>
        <Cell colspan={4}>Paralysis</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q5')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Hx of falls in last 6 months</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q6')}/>
        <Cell colspan={4}>Metal implants or prosthesis</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q7')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Joint replacement</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.muscularSkeletal.muscularSkeletal_q8')}/>
        <Cell colspan={4}>Cancer</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.otherCondition.otherCondition_q1')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>OTC Medications</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medications.NSAIDS')}/>
        <Cell colspan={4}>Oxygen</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Pulmonary.oxygen')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Fainting</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.neurological.neurological_q2')}/>
        <Cell colspan={4}>Pre-op physical</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreVisitRequirements.preOpPhysical')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Other Medical Conditions</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.otherCondition.otherCondition_q3')}/>
        <Cell colspan={4}>Stress Test</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.cardiacStressTest')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Tuberculosis</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Pulmonary.Tuberculosis')}/>
        <Cell colspan={4}>Psychiatric Assessment</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('social.mentalHealth')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Previous Surgeries</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('priorSurgeries.q1')}/>
        <Cell colspan={4}>Cardiac Echo</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.cardiacEcho')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Pneumonia</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Pulmonary.Pneumonia')}/>
        <Cell colspan={4}>Steroids</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medications.steroids')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Cardiac Catheterization</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.cardiacCatheterization')}/>
        <Cell colspan={4}>Stroke or TIA</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.neurological.neurological_q5')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Previous problems with anesthesia</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.associatedProblem')}/>
        <Cell colspan={4}>CHF</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.CongestiveHeartFailure')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Migraines</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.neurological.neurological_q6')}/>
        <Cell colspan={4}>Problems with airway</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('PreProcedureAssessment.airwayProblem')}/>
      </Section>

      <Section outlined>
        <Cell colspan={4}>Heart stents</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('Cardiac.Stents')}/>
        <Cell colspan={4}>Seizures</Cell>
        <Field colspan={2} questionnaire={selectBooleanText('medicalConditions.neurological.neurological_q7')}/>
      </Section>
      </div>
      <Spacer />
      <FooterSection />
    </Form>
    <Form title="HISTORY & PHYSICAL (page 10)">
      <HeaderSection />
      <br />
      <Section>
        <Cell colspan={4}>Patient/Family/Complications:</Cell>
        <Field colspan={4} questionnaire={selectStringWithPrefix('Patient:',  'PreProcedureAssessment.associatedProblem.yes.associatedProblem_text2')} />
        <Field colspan={4} questionnaire={selectStringWithPrefix('Family:', 'PreProcedureAssessment.associatedProblem.yes.associatedProblem_text')} />
      </Section>

      <Section>
        <Field label="Preoperative Diagnosis" name="page9.preopDiagnosis"/>
      </Section>

      <br />
      <AllergyList />
      <br />
      <MedicationsList />
      <SurgeriesList />

      <Spacer />
      <FooterSection />
    </Form>
    <Form title="Patient Sleep Apnea Questionnaire">
      <HeaderSection />
      <br />
      <Section>
        <Field label="Sleep Apnea" questionnaire={selectBooleanText('Pulmonary.SleepApnea')}/>
      </Section>
      <br/>
      <Section outlined>
        <Cell colspan={3}><strong>Yes</strong></Cell>
        <Cell colspan={3}><strong>No</strong></Cell>
        <Cell colspan={6}></Cell>
        <Checkbox colspan={3} name="page10.snoreYes" questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q1')}/>
        <Checkbox colspan={3} name="page10.snoreNo" negative questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q1')}/>
        <Cell colspan={6}>Have you been told that you snore?</Cell>
        <Checkbox colspan={3} name="page10.tiredYes" questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q2')}/>
        <Checkbox colspan={3} name="page10.tiredNo" questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q2')}/>
        <Cell colspan={6}>Are you often tired during the day?</Cell>
        <Checkbox colspan={3} name="page10.stopBreathingYes" questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q3')}/>
        <Checkbox colspan={3} name="page10.stopBreatingNo" questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q3')}/>
        <Cell colspan={6}>
          Do you know if you stop breathing or has anyone witnessed you stop breathing while you were sleeping?
        </Cell>
        <Checkbox colspan={3} name="page10.highBloodPressureYes" questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q4')}/>
        <Checkbox colspan={3} name="page10.highBloodPressureNo" questionnaire={selectBoolean('sleepApneaQ.SleepApneaQ_q4')}/>
        <Cell colspan={6}>Do you have high blood pressure or take medication to control high blood pressure?</Cell>
        <Cell colspan={3}></Cell>
        <Cell colspan={3}></Cell>
        <Cell colspan={6}>
          <b>If any of the above four questions are answered 'yes', please proceed to questions 5 through 8 below.</b>
        </Cell>
        <Checkbox colspan={3} name="page10.bmi35Yes" calculated={() => isBmiGreaterThan(35)}/>
        <Checkbox colspan={3} name="page10.bmi35No" calculated={() => isBmiGreaterThan(35)} negative/>
        <Cell colspan={6}>Is your body mass index greater than 35?</Cell>
        <Checkbox colspan={3} name="page10.older50Yes" calculated={() => isOlderThen(50) }/>
        <Checkbox colspan={3} name="page10.older50No" calculated={() => isOlderThen(50) } negative/>
        <Cell colspan={6}>Are you 50 years old or older?</Cell>
        <Checkbox colspan={3} name="page10.neckYes" calculated={() => isNeck()}/>
        <Checkbox colspan={3} name="page10.neckNo" calculated={() => isNeck()} negative/>
        <Cell colspan={6}>Does your neck measure more than 15 3/4 inches (40cm) around (collar size)?</Cell>
        <Checkbox colspan={3} name="page10.maleYes" calculated={() => isMale()}/>
        <Checkbox colspan={3} name="page10.maleNo" calculated={() => isMale()} negative/>
        <Cell colspan={6}>Are you a male?</Cell>
        <Field label="Risk Assessment" colspan={12} name="" />
      </Section>
      <Spacer />
      <FooterSection />
    </Form>
    <Form title="Polaris Surgery Center">
      <br />
      <FallRiskAssessment />
      <br />
      <p>To obtain the Morse Fall Score, add the score from each category.</p>
      <br />
      <div style={{ width: '50%' }}>
        <Section outlined>
          <Cell colspan={12}><strong>Morse Fall Risk</strong></Cell>
          <Cell colspan={6}>High Risk</Cell>
          <Cell colspan={6}>45 and higher</Cell>
          <Cell colspan={6}>Moderate Risk</Cell>
          <Cell colspan={6}>25-44</Cell>
          <Cell colspan={6}>Low Risk</Cell>
          <Cell colspan={6}>0-24</Cell>
        </Section>
      </div>
      <br />
      <div style={{display: 'flex', flexDirection: 'row', textAlign: 'center'}}>
        <div style={{ paddingTop: '1.5rem' }}>Pre-Op Nurse Signature: __________________________________</div>
        <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Date/Time: _______________________</div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row', textAlign: 'center'}}>
        <div style={{ paddingTop: '1.5rem' }}>Post-Op Nurse Signature: _________________________________</div>
        <div style={{ marginLeft: '1rem', marginTop: '1.5rem' }}>Date/Time: _______________________</div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div style={{ textAlign: 'center' }}>
        All patients at Polaris Surgery Center are considered high risk for Falls.
      </div>
    </Form>
  </>
);

medicalPassport8n.title = 'Polaris Surgery Center';

const HeaderSection = () => (
  <div className={'smaller-font'}>
    <Section>
      <Field name="procedure.patient.name" colspan={4} label="Patient Name" />
      <Field name="dob" colspan={2} label="DOB" questionnaire={selectString('demographicInformation.dob')} />
      <Field label="Age" colspan={2} name="age" questionnaire={selectAge('demographicInformation.dob')} />
      <Field label="HT" colspan={2} name="ht" questionnaire={selectHeight('demographicInformation.bmi')} />
      <Field label="WT" colspan={2} name="wt" questionnaire={selectWeightLbs('demographicInformation.bmi')} />
      <PrimaryPhoneField
        label="Primary Phone"
        colspan={4}
        name="primaryPhone"/>

      <Field label="Home" colspan={2} name="homePhone" questionnaire={selectString('demographicInformation.HomePhone')} />
      <Field label="Cell" colspan={2} name="cellPhone" questionnaire={selectString('demographicInformation.CellPhone')} />
      <Field label="Work" colspan={2} name="workPhone" questionnaire={selectString('demographicInformation.WorkPhone')} />
      <Field label="BMI" colspan={1} name="bmi" questionnaire={selectBMI('demographicInformation.bmi')} />
      <Field label="KG" colspan={1} name="kg" questionnaire={selectWeight('demographicInformation.bmi')} />

      <Checkbox
        label="Ride Home"
        colspan={2}
        name="rideHome"
        questionnaire={selectBoolean('demographicInformation.ridehomeInfo')}
      />
      <Field colspan={4} name="rideHomeInfo" questionnaire={selectString('demographicInformation.ridehomeInfo.yes')} />
      <Field label="Physician Name" colspan={4} name="procedure.physician.name" />
      <Field label="DOS" colspan={2} name="procedure.serviceTime" />
      <Field label="Procedure" name="procedure.name" />
    </Section>
  </div>
);

const HeaderSection2 = () => (
  <Section outlined>
        <Cell colspan={6}>Surgical History and Physical/Medication Reconciliation</Cell>
        <Cell colspan={6}>Polaris Surgery Center</Cell>
        <Field label="PreOp Diagnosis" colspan={6} name="" />
        <Field label="Patient name" colspan={6} name="procedure.patient.name" />
        <Field label="Planned Procedure" colspan={6} rowspan={2} name="procedure.name" />
        <Field
          label="DOB"
          colspan={3}
          name="page5.DOB"
          questionnaire={selectString('demographicInformation.dob')}
        />
        <Field label="Physician" colspan={3} name="procedure.physician.name" />
        <Field label="DOS" colspan={3} name="procedure.serviceTime" />
        <Field label="Account#" colspan={3} name="" />

        <Field label="Age" colspan={2} name="page5.age" questionnaire={selectAge('demographicInformation.dob')} />
        <Field
          label="Sex"
          colspan={2}
          name="page5.sex"
          questionnaire={selectHighlightedString('demographicInformation.sex')}
        />
        <Field label="Ht" colspan={2} name="ht" questionnaire={selectHeight('demographicInformation.bmi')} />
        <Field label="Wt" colspan={2} name="wt" questionnaire={selectWeightLbs('demographicInformation.bmi')} />
        <Field label="KG" colspan={2} name="kg" questionnaire={selectWeight('demographicInformation.bmi')} />
        <Field label="BMI" colspan={2} name="bmi" questionnaire={selectBMI('demographicInformation.bmi')} />
      </Section>
)

interface PrimaryPhoneFieldProps extends LabeledCellProps {
  name: string;
}
const PrimaryPhoneField = ({ name, label, colspan }: PrimaryPhoneFieldProps) => {
  const { questionnaireAnswers } = useValue();
  const preferred = get(questionnaireAnswers, 'sections.demographicInformation.schema.preferred.option', '') + 'Phone';

  return (<Field name={name} label={label} colspan={colspan} questionnaire={selectString(`demographicInformation.${preferred}`)}/>)
}

const FooterSection = () => (
  <Section>
    <Field
      label="Patient Name"
      colspan={5}
      name="procedure.patient.name"
      questionnaire={selectString('demographicInformation.name')}
    />
    <Field label="DoB" colspan={3} name="dob" questionnaire={selectString('demographicInformation.dob')} />
    <Field label="Date of Service" colspan={4} name="procedure.serviceTime" />
  </Section>
);

const AllergyList = () => {
  const {allergies, answered, emptyName} = useAllergies();

  return (
    <Section outlined={true} title={`Allergies (Patient answered ${answered ? '☑' : '☐'})`}>
      <Cell colspan={6}><strong>Name of Allergy</strong></Cell>
      <Cell colspan={6}><strong>Reaction of sensitivity</strong></Cell>
      {allergies.map((allergy: Allergy, index) => (
        <Fragment key={`allergy-${index}`}>
          <Cell colspan={6}>{allergy.name}</Cell>
          <Cell colspan={6}>{allergy.reaction}</Cell>
        </Fragment>
      ))}
      {allergies.length === 0 && (
        <>
          <Cell colspan={6}>{emptyName}</Cell>
          <Cell colspan={6}></Cell>
        </>
      )}
    </Section>
  )
}

const MedicationsList = () => {
  const {medications, answered, emptyName} = useMedications();

  return (
    <Fragment>
      <Section outlined={true} title={`Medications (Patient answered ${answered ? '☑' : '☐'})`}>
        <Cell colspan={9}><strong>Medication, dosage, route, frequency</strong></Cell>
        <Cell colspan={3}><strong>Reason</strong></Cell>
      </Section>
      {medications.length === 0 && (
        <>
          <Cell colspan={6}>{emptyName}</Cell>
          <Cell colspan={6}></Cell>
        </>
      )}
      {medications.map((m: Medication) => (
        <Section outlined>
          <Cell colspan={9}>{[m.name, m.dosage, m.route, m.frequency].join(',')}</Cell>
          <Cell colspan={3}>{m.purpose}</Cell>
        </Section>
      ))}
    </Fragment>
  )
}

const MedicationReconciliationList = () => {
  const {medications, answered, emptyName} = useMedications();

  return (
    <Fragment>    
      <Section outlined>
        <Cell colspan={6}>Medication, Dosage, Route, Frequency</Cell>
        <Cell colspan={2}>Last Taken</Cell>
        <Cell colspan={2}>Reason</Cell>
        <Cell colspan={2}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div><b>C</b>ont = Continue Med</div>
            <div><b>H</b>old = Hold medication</div>
            <div><b>N</b>ew = New Prescription given</div>
            <div><b>P</b>CP = Check with PCP</div>
          </div>
        </Cell>
      </Section>
      {medications.length === 0 && (
        <>
          <Cell colspan={6}>{emptyName}</Cell>
          <Cell colspan={2}></Cell>
          <Cell colspan={2}></Cell>
          <Cell colspan={2}></Cell>
        </>
      )}      
      {medications.map((m: Medication, i) => (
        <Section outlined>
          <Cell colspan={6}>{[m.name, m.dosage, m.route, m.frequency].join(',')}</Cell>
          <Cell colspan={1}></Cell>
          <Field colspan={1} name={`medication[${i}].lastTaken`} />
          <Cell colspan={2}>{m.purpose}</Cell>
          <Cell colspan={2}>
            <Checkbox label="C" colspan={1} name={`medication[${i}].cont`} className="f-checkbox-centered" />
            <Checkbox label="H" colspan={1} name={`medication[${i}].hold`} className="f-checkbox-centered" />
            <Checkbox label="N" colspan={1} name={`medication[${i}].new`} className="f-checkbox-centered" />
            <Checkbox label="P" colspan={1} name={`medication[${i}].pcp`} className="f-checkbox-centered" />
          </Cell>
        </Section>
      ))}
    </Fragment>
  )
}

const SurgeriesList = () => {
  const {surgeries, answered, emptyName} = useSurgeries();

  return (
    <Fragment>
      <Section outlined={true} title={`Surgeries (Patient answered ${answered ? '☑' : '☐'})`}>
        <Cell colspan={4}>Procedure</Cell>
        <Cell colspan={4}>Year</Cell>
        <Cell colspan={4}>Anesthesia Type</Cell>
      </Section>
      {surgeries.length === 0 && (
        <>
          <Cell colspan={6}>{emptyName}</Cell>
          <Cell colspan={6}></Cell>
        </>
      )}
      {surgeries.map((p: Surgery) => (
        <Section outlined>
          <Cell colspan={4}>{p.procedure}</Cell>
          <Cell colspan={4}>{p.year}</Cell>
          <Cell colspan={4}>{p.anesthesia}</Cell>
        </Section>
      ))}
    </Fragment>
  )
}

const AllergiesCell = ({ colspan, rowspan }: CellProps) => {
  const {allergies, emptyName} = useAllergies();

  if (!allergies.length) return <Cell colspan={colspan} rowspan={rowspan}>{emptyName}</Cell>

  return (
    <Cell colspan={colspan} rowspan={rowspan}>
      {allergies.map((a, index) => (
        <Fragment key={index}>
          {`${a.name}, ${a.reaction}`}
          {index < allergies.length - 1 && <br />}
        </Fragment>
      ))}
    </Cell>
  );
};

const SurgeriesCell = ({ colspan, rowspan }: CellProps) => {
  const { surgeries, emptyName } = useSurgeries();

  if (!surgeries.length) {
    return <Cell colspan={colspan} rowspan={rowspan}>{emptyName}</Cell>;
  }

  return (
    <Cell colspan={colspan} rowspan={rowspan}>
      {surgeries.map((s, index) => (
        <Fragment key={index}>
          {`* ${s.procedure}${s.year ? `, ${s.year}` : ''}`}
          {index < surgeries.length - 1 && <br />}
        </Fragment>
      ))}
    </Cell>
  );
};

interface FreeEntryLineProps {
  title: string;
}

const FreeEntryLine = ({title} : FreeEntryLineProps) => (
  <Cell colspan={3}>
    <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center' }}>
      <div>
        <div style={{ paddingTop: '1.5rem' }}>_________________________ </div>
        <div style={{ textAlign: 'left', fontSize: '.8rem' }}>
          {title}
        </div>
      </div>
    </div>
  </Cell>
)

interface ReviewCellProps {
  title: string;
  paths: string[][];
  insideSection?: boolean;
  colspanTitle?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colspanDesc?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  rightmostCell?: CellProps;
}

const ReviewCell = ({ title, paths, colspanTitle, colspanDesc, insideSection, rightmostCell }: ReviewCellProps) => {
  const reviewCell = (
    <>
      <Cell colspan={colspanTitle}>{title}</Cell>
      <Field
        colspan={colspanDesc}
        questionnaire={selectHighlightedString2(...paths.map(([category, value]) => `${category}.${value}`))}
      />
      {rightmostCell ? <Cell colspan={rightmostCell.colspan} rowspan={rightmostCell.rowspan}>{rightmostCell.children}</Cell> : null }
    </>
  );

  if (!insideSection) {
    return (<Section outlined>{reviewCell}</Section>);
  }

  return reviewCell;
};

const EmploymentAddressCell = ({ label, colspan, rowspan}: LabeledCellProps) => {
  const { questionnaireAnswers } = useValue();

  const addressText = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_address.text',
    ''
  );
  const city = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_additinalAddress.cityStateZip.city',
    ''
  );
  const state = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_additinalAddress.cityStateZip.state',
    ''
  );
  const zipCode = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.employment_info.option[1].schema.employment_additinalAddress.cityStateZip.zipCode',
    ''
  );
  const address = [addressText, city, state, zipCode].filter(i => !!i).join(', ');

  return (<LabeledCell label={label} rowspan={rowspan} children={address}/>)
}

const AddressCell = ({ label, colspan, rowspan}: LabeledCellProps) => {
  const { questionnaireAnswers } = useValue();

  const addressText = get(questionnaireAnswers, 'sections.demographicInformation.schema.address.text', '');
  const city = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.additinalAddress.cityStateZip.city',
    ''
  );
  const state = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.additinalAddress.cityStateZip.state',
    ''
  );
  const zipCode = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.additinalAddress.cityStateZip.zipCode',
    ''
  );

  const address = [addressText, city, state, zipCode].filter(i => !!i).join(', ');

  return (<LabeledCell label={label} rowspan={rowspan} children={address}/>)
}

const FallRiskAssessment = () => {
  // Keep track of each checkbox’s numeric score
  const [scores, setScores] = useState<Record<string, number>>({});

  // Single helper: set the score if checked, or 0 if unchecked
  const handleCheckboxChange = (checkboxName: string, checkValue: number) => (checked: boolean) => {
    setScores(prev => ({ ...prev, [checkboxName]: checked ? checkValue : 0 }));
  };

  // Summation of all stored scores
  const newTotalScore = Object.values(scores).reduce((acc, cur) => acc + cur, 0);

  // all scores excluding DOS
  const totalScore = Object.entries(scores).filter(([k]) => !k.toLowerCase().includes('.dos')).reduce((acc, [, v]) => acc + v, 0);

  return (
    <Section title="Fall Risk Assessment" outlined>
      {/* Header */}
      <Cell colspan={6}>Risk Factor(s)</Cell>
      <Cell colspan={3}>Scale</Cell>
      <Cell colspan={3}>Score</Cell>

      {/* History Of Falls */}
      <Cell colspan={6} rowspan={2}>
        Have you fallen in the last 6 months?
      </Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.historyOfFalls"
        questionnaire={selectBoolean('medicalConditions.muscularSkeletal.muscularSkeletal_q6')}
        // 25 points if “Yes”
        onChanged={handleCheckboxChange('fallRiskAssessment.historyOfFalls', 25)}
      />
      <Cell colspan={3}>25</Cell>
      <Checkbox
        label="No"
        colspan={3}
        name="fallRiskAssessment.historyOfFalls"
        negative
        // 0 points if “No”
        onChanged={handleCheckboxChange('fallRiskAssessment.historyOfFalls', 0)}
      />
      <Cell colspan={3}>0</Cell>

      {/* Secondary Diagnosis */}
      <Cell colspan={6} rowspan={2}>
        Secondary Diagnosis (2 or more medical diagnoses)(s)
      </Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.secondaryYes"
        // 15 points
        onChanged={handleCheckboxChange('fallRiskAssessment.secondaryYes', 15)}
      />
      <Cell colspan={3}>15</Cell>
      <Checkbox
        label="No"
        colspan={3}
        name="fallRiskAssessment.secondaryNo"
        // 0 for “No”
        onChanged={handleCheckboxChange('fallRiskAssessment.secondaryYes', 0)}
      />
      <Cell colspan={3}>0</Cell>

      {/* Ambulatory Aid */}
      <Cell colspan={3} rowspan={3}>
        Ambulatory Aid
      </Cell>
      <Cell colspan={3}>None/Nurse Assist/Wheelchair</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.ambulatory1Yes"
        // 0 points for “None/Nurse Assist”
        onChanged={handleCheckboxChange('fallRiskAssessment.ambulatoryAid', 0)}
      />
      <Cell colspan={3}>0</Cell>
      <Cell colspan={3}>Crutches/Cane/Walker</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.ambulatory2Yes"
        // 15 points
        onChanged={handleCheckboxChange('fallRiskAssessment.ambulatoryAid', 15)}
      />
      <Cell colspan={3}>15</Cell>
      <Cell colspan={3}>Furniture- clutches ot ambulate</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.ambulatory3Yes"
        // 30 points
        onChanged={handleCheckboxChange('fallRiskAssessment.ambulatoryAid', 30)}
      />
      <Cell colspan={3}>30</Cell>

      {/* IV or IV Access */}
      <Cell colspan={6} rowspan={2}>
        IV or IV Access
      </Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.IVYes"
        // 25 points
        onChanged={handleCheckboxChange('fallRiskAssessment.IVAccess', 25)}
      />
      <Cell colspan={3}>25</Cell>
      <Checkbox
        label="No"
        colspan={3}
        name="fallRiskAssessment.IVNo"
        onChanged={handleCheckboxChange('fallRiskAssessment.IVAccess', 0)}
      />
      <Cell colspan={3}>0</Cell>

      {/* Gait */}
      <Cell colspan={3} rowspan={3}>
        Gait
      </Cell>
      <Cell colspan={3}>Normal/Wheelchair</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.gait1Yes"
        onChanged={handleCheckboxChange('fallRiskAssessment.gait', 0)}
      />
      <Cell colspan={3}>0</Cell>
      <Cell colspan={3}>Weak</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.gait2Yes"
        // 10 points
        onChanged={handleCheckboxChange('fallRiskAssessment.gait', 10)}
      />
      <Cell colspan={3}>10</Cell>
      <Cell colspan={3}>Impaired</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.gait3Yes"
        // 20 points
        onChanged={handleCheckboxChange('fallRiskAssessment.gait', 20)}
      />
      <Cell colspan={3}>20</Cell>

      {/* Mental Status */}
      <Cell colspan={3} rowspan={2}>
        Mental Status
      </Cell>
      <Cell colspan={3}>Knows own limits</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.mental1Yes"
        onChanged={handleCheckboxChange('fallRiskAssessment.mentalStatus', 0)}
      />
      <Cell colspan={3}>0</Cell>
      <Cell colspan={3}>Overestimates or forgets limits</Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.mental2Yes"
        // 15 points
        onChanged={handleCheckboxChange('fallRiskAssessment.mentalStatus', 15)}
      />
      <Cell colspan={3}>15</Cell>

      {/* First total */}
      <Cell colspan={8}>Total score</Cell>
      <Cell colspan={4}>{totalScore}</Cell>

      {/* Add DOS if applicable */}
      <Cell colspan={6} rowspan={2}>
        Add DOS if applicable: Peripheral Nerve Block Lower Extremeity
      </Cell>
      <Checkbox
        label="Yes"
        colspan={3}
        name="fallRiskAssessment.dosYes"
        // 20 points
        onChanged={handleCheckboxChange('fallRiskAssessment.DOS', 20)}
      />
      <Cell colspan={3}>20</Cell>
      <Checkbox
        label="No"
        colspan={3}
        name="fallRiskAssessment.dosNo"
        onChanged={handleCheckboxChange('fallRiskAssessment.DOS', 0)}
      />
      <Cell colspan={3}>0</Cell>

      {/* Updated total */}
      <Cell colspan={8}>New Total score</Cell>
      <Cell colspan={4}>{newTotalScore}</Cell>
    </Section>
  );
};

// Utility functions

function isNeck(): boolean | null {
  const { questionnaireAnswers } = useValue();
  const neckSize = get(questionnaireAnswers, 'sections.sleepApneaQ.schema.SleepApneaQ_q5.option', '');

  switch (neckSize) {
    case 'Yes': return true;
    case 'No': return false;
    default: return null;
  }
}

function isMale(): boolean | null {
  const { questionnaireAnswers } = useValue();
  const male = get(questionnaireAnswers, 'sections.demographicInformation.schema.sex.option', '');

  if (male == null) return null;

  if (male === 'Male') {
    return true;
  }

  return false;
}

function isOlderThen(value: number): boolean | null {
  const { questionnaireAnswers } = useValue();

  const birthDay = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.day', '');
  const birthMonth = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.month', '');
  const birthYear = get(questionnaireAnswers, 'sections.demographicInformation.schema.dob.dateOfBirth.year', '');

  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero-indexed
  const currentDay = currentDate.getDate();

  // Calculate the age
  let age = currentYear - birthYear;

  // Adjust the age based on the birth month and day
  if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
    age--;
  }

  // Check if the age is greater than 50
  return age > value;
}

function isBmiGreaterThan(value: number): boolean | null {
  const { questionnaireAnswers } = useValue();
  const heightFeet = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.bmi.bodyMassIndex.heightFt',
    ''
  );
  const heightInches = get(
    questionnaireAnswers,
    'sections.demographicInformation.schema.bmi.bodyMassIndex.heightIn',
    ''
  );
  const weight = get(questionnaireAnswers, 'sections.demographicInformation.schema.bmi.bodyMassIndex.weightLb', '');


  // Convert input strings to numbers
  const feet = parseFloat(heightFeet);
  const inches = parseFloat(heightInches);
  const weightNum = parseFloat(weight);

  // Check if the conversion was successful
  if (isNaN(feet) || isNaN(inches) || isNaN(weightNum)) {
    // Handle invalid input
    return null;
  }

  // Convert height to inches
  const totalHeightInInches = feet * 12 + inches;

  // Calculate BMI (weight in pounds / (height in inches * height in inches)) * 703
  const bmi = (weightNum / (totalHeightInInches * totalHeightInInches)) * 703;

  // Check if BMI is greater than specified value
  if (bmi > value) {
    return true;
  } else {
    return false;
  }
}

const hasPolicy = (): boolean | null => {
  const { questionnaireAnswers } = useValue();
  const policy = get(questionnaireAnswers, 'sections.documentElectronicSignature.value.patientRights', false);

  return policy != null;
}

function employmentStatus() {
  const { questionnaireAnswers } = useValue();
  const employmentStatus = get(questionnaireAnswers, 'sections.demographicInformation.schema.employment_info.option[0]');
  const employment = get(questionnaireAnswers, 'sections.demographicInformation.schema.employment_info.option');

  if (employmentStatus === 'Full time' || employmentStatus === 'Part time') {
    return employmentStatus;
  }

  return employment;

  return employmentStatus;
}

function selectRaw(path: string) {
  const { questionnaireAnswers } = useValue();
  const value = get(questionnaireAnswers, path);
  return value;
}
