import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';
import React from 'react';
import { OR_TURNOVER_COLORS } from '../PieCharts';
import { useTheme } from '@material-ui/core';
import AnalyticsPanel from '../../components/AnalyticsPanel';
import ChartLegend from './ChartLegend';

const BarTurnoverByOr = ({ data }) => {
  const theme = useTheme();

  return (
    <AnalyticsPanel title="Turnover By OR">
      <ResponsiveContainer width="100%" height="100%" aspect={0.85}>
        <BarChart
          width={300}
          height={300}
          barGap={20}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.25)" />
          <XAxis type="category" dataKey="title" stroke="rgba(255,255,255,0.25)" />
          <Tooltip
            content={({ active, payload, label }) => (
              <CustomTooltip active={active} payload={payload} label={label} unit="minute" decimal />
            )}
            cursor={{ fill: 'rgba(255,255,255,0.15)' }}
            contentStyle={{
              backgroundColor: theme.palette.background.paper,
              borderRadius: 4,
              border: 0,
            }}
          />
          <Bar dataKey="turnover" stackId="a" fill={OR_TURNOVER_COLORS[0]} />
          <Legend content={ChartLegend} align="left" verticalAlign="bottom" iconType="circle" iconSize={8} />
        </BarChart>
      </ResponsiveContainer>
    </AnalyticsPanel>
  );
};

export default BarTurnoverByOr;
