import React, { DetailedHTMLProps, LegacyRef, TextareaHTMLAttributes, useLayoutEffect, useRef } from 'react';
import { useValue } from '../components/entities/surgeonProcedures/predefinedForms/ValueContext';
import { useQuestionnaireValueViaSelector } from '../components/entities/surgeonProcedures/predefinedForms/hooks';

const Field = (props: DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>) => {
  const wrapRef = useRef<HTMLDivElement>();
  const textareaRef = useRef<HTMLTextAreaElement>();

  useLayoutEffect(() => {
    wrapRef.current!.setAttribute('data-replicated-value', textareaRef.current!.value);

    textareaRef.current!.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true,
      })
    );
  }, []);

  return (
    <div ref={wrapRef as LegacyRef<HTMLDivElement>} className="grow-wrap" data-replicated-value={props.value}>
      <textarea
        ref={textareaRef as LegacyRef<HTMLTextAreaElement>}
        rows={1}
        onInput={() => {
          wrapRef.current!.dataset.replicatedValue = textareaRef.current!.value;
        }}
        {...props}
      />
    </div>
  );
};

const QuestionnaireField = ({ questionnaire }: ImmutableQuestionnaireValue) => {
  const questionnaireValue = useQuestionnaireValueViaSelector(questionnaire);

  return <Field value={questionnaireValue || ''} />;
};

const Mutable = ({ name, questionnaire }: MutableTextareaProps) => {
  const { defaultValue, value, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValueViaSelector(questionnaire);

  return (
    <Field
      name={name}
      value={value || questionnaireValue || defaultValue || ''}
      onChange={e => setValue(e.target.value)}
    />
  );
};

const Immutable = (props: ImmutableTextareaProps) => {
  return 'value' in props ? <Field {...props} /> : <QuestionnaireField {...props} />;
};

export type TextareaProps = MutableTextareaProps | ImmutableTextareaProps;

interface MutableTextareaProps {
  name: string;
  questionnaire?: (questionnaire: unknown, questionnaireValue: unknown) => string;
}

type ImmutableTextareaProps = ImmutableValue | ImmutableQuestionnaireValue;

interface ImmutableValue {
  value: string;
}

interface ImmutableQuestionnaireValue {
  questionnaire: (questionnaire: unknown, questionnaireValue: unknown) => string;
}

const Textarea = (
  props: TextareaProps &
    Omit<
      DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
      'name' | 'value' | 'onChange'
    >
) => ('name' in props ? <Mutable {...props} /> : <Immutable {...props} />);

export default Textarea;
