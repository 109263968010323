export interface Page {
  element: HTMLElement;
  nextPage?: Page;
  destroy(): void;
}

export async function createPage(
  content: HTMLElement | null,
  abortSignal: AbortSignal
): Promise<Page> {
  const element = document.createElement('div');
  element.classList.add('page');

  const header = document.createElement('div');
  header.classList.add('header');

  const contents = document.createElement('div');
  contents.classList.add('contents');

  if (content) {
    contents.appendChild(content);
  }

  element.appendChild(header);
  element.appendChild(contents);

  console.log(abortSignal);

  const destroy = () => {
    element.remove();
    page.nextPage?.destroy();
  };

  const page: Page = {
    element,
    destroy,
  };

  return page;
}
