import toString from '../questionnaire/toString';
import createSelector from '../questionnaire/selector';
import { toPath } from 'lodash';
import toHighlightsString from '../questionnaire/toHighlightsString';
import toHighlightsString2 from '../questionnaire/toHighlightsString2';
import toAge from '../questionnaire/toAge';
import toBoolean from '../questionnaire/toBoolean';
import toHeight from '../questionnaire/toHeight';
import toWeightLbs from '../questionnaire/toWeightLbs';
import toWeight from '../questionnaire/toWeight';
import toBMI from '../questionnaire/toBMI';

export const select =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): unknown =>
    createSelector(toPath(path))(questionnaire, questionnaireValue)?.[1];

export const selectString =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toString(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
  };

  export const selectStringWithPrefix =
  (
    prefix: string,
    ...paths: string[]
  ) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const result = selectString(...paths)(questionnaire, questionnaireValue);

    return `${prefix}${result}`;
  };  

export const selectHighlightedString =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toHighlightsString(...selection)) || '';
  };

export const selectHighlightedString2 =
  (...paths: string[]) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selections = paths
      .map(p => createSelector(toPath(p))(questionnaire, questionnaireValue))
      .flatMap(v => (v ? [toHighlightsString2(...v)] : []))
      .filter(Boolean);
    return selections.join(', ');
};

export const selectAge =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toAge(...selection)) || '';
  };

export const selectHeight =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toHeight(...selection)) || '';
  };

export const selectWeightLbs =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toWeightLbs(...selection)) || '';
  };

export const selectWeight =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toWeight(...selection)) || '';
  };

export const selectBMI =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toBMI(...selection)) || '';
  };

export const selectBoolean =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): boolean => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (selection && toBoolean(...selection)) || false;
  };


export const selectBooleanText =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): string => {
    return selectBoolean(path)(questionnaire, questionnaireValue) ? 'yes' : 'no';
  }

export const selectList =
  (path: string) =>
  (questionnaire: unknown, questionnaireValue: unknown): unknown[] => {
    const selection = createSelector(toPath(path))(questionnaire, questionnaireValue);
    return (Array.isArray(selection) && selection[1] && Array.isArray(selection[1].list) && selection[1].list) || [];
  };
