import Typography from '@material-ui/core/Typography';
import React from 'react';
import MultiBarChart from './MultiBarChart';
import { groupBy } from 'lodash';
import { roundValue } from '../Analytics';
import { Box, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';

export const barColors = [
  '#00A7F7',
  '#F55323',
  '#263778',
  '#cecccc',
  '#FFAC00',
  '#7ED321',
  '#F8E71C',
  '#50E3C2',
  '#B057FF',
  '#FF5ABB',
  '#B29377',
];

const unitValue = (value: number, unit: any) => [roundValue(value), unit].join(' ');

export default ({ distribution, barsData, ratio }) => {
  const theme = useTheme();
  const classes = useStyles();
  const labels = groupBy(distribution, 'date');
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {barsData.map(({ value, unit, title, color }, i) => (
        <Box className={classes.legend} key={i}>
          <Box className={classes.dot} style={{ backgroundColor: color }} color={color} />
          <Typography variant="body2">{title}</Typography>
          <Typography variant="body2">{unitValue(value, unit)}</Typography>
        </Box>
      ))}
      {!isMobile && <MultiBarChart bars={barsData} value={distribution} labels={labels} ratio={ratio} />}
    </>
  );
};

const useStyles = makeStyles({
  legend: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5em',
  },
  dot: {
    width: '0.85em',
    height: '0.85em',
    borderRadius: '50%',
  },
});
