import { useMemo } from 'react';
import { useValue } from './ValueContext';
import createSelector from '../../../../questionnaire/selector';
import toString from '../../../../questionnaire/toString';
import get from 'lodash/get';

export function useQuestionnaireValueViaSelector(selector) {
  const { questionnaire, questionnaireAnswers } = useValue();

  return useMemo(
    () => (selector ? selector(questionnaire, questionnaireAnswers) : undefined),
    [selector, questionnaire, questionnaireAnswers]
  );
}

export function useQuestionnaireValue(reducer, ...paths) {
  const { questionnaire, questionnaireAnswers } = useValue();

  return useMemo(() => {
    const actualPaths = paths.filter(path => path);

    if (actualPaths.length === 0) {
      return undefined;
    } else if (actualPaths.length === 1) {
      const value = createSelector(actualPaths[0])(questionnaire, questionnaireAnswers);
      return value && reducer(...value);
    } else {
      return reducer.spec.List(
        '',
        actualPaths
          .map(createSelector)
          .map(selector => selector(questionnaire, questionnaireAnswers))
          .map(value => value && (reducer ? reducer(...value) : toString(...value)))
          .filter(value => value)
      );
    }
  }, [paths, questionnaire, questionnaireAnswers, reducer]);
}

export function useQuestionnaireStringValue(...paths) {
  const { questionnaire, questionnaireAnswers } = useValue();

  return useMemo(
    () =>
      paths
        .filter(path => path)
        .map(path => {
          if (typeof path === 'string') {
            return get(questionnaireAnswers, path);
          } else {
            return createSelector(path)(questionnaire, questionnaireAnswers);
          }
        })
        .map(value => value && toString(...value))
        .filter(value => value)
        .join(', '),
    [paths, questionnaire, questionnaireAnswers]
  );
}

export function useQuestionnaireBooleanValue(reducer, path) {
  const { questionnaire, questionnaireAnswers } = useValue();

  const selector = useMemo(() => createSelector(path || []), [path]);

  return useMemo(() => {
    if (!path) {
      return undefined;
    }
    const value = selector(questionnaire, questionnaireAnswers);
    return value && reducer(...value);
  }, [reducer, path, selector, questionnaire, questionnaireAnswers]);
}
