import React from 'react';
import styled from 'styled-components';
import responsive from '../../../se/utilities/responsive';
import AnalyticsPanel from '../../pages/analytics/components/AnalyticsPanel';

const StatLabel = styled.label`
  text-transform: uppercase;
  font-weight: 400;
  color: ${props => (!!props.color ? props.color : '#abe3ff')};
  opacity: ${props => (!!props.color ? undefined : '0.5')};
  ${responsive.sm.andSmaller`
      font-size: 0.75em;
  `};
`;

const Section = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  text-align: center;

  ${responsive.md.andSmaller`
    flex: 1;
  `};
`;

const Value = styled.label`
  font-size: 1.75em;
  line-height: 1.3;
  margin: 0;
  color: ${props => (!!props.color ? props.color : 'white')};
  ${responsive.md.andSmaller`
    font-size: 1.5em;
    margin-bottom: 0;
  `};
`;

const Flow = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  ${responsive.md.andSmaller`
    flex-direction: row;
    margin-top: -1em;
  `};
`;

export const HospitalStats = ({ population, voters = 0, votes = 0, turnout = 0, color = undefined }) => (
  <AnalyticsPanel title={'Stats'}>
    <Flow>
      <Section>
        <StatLabel color={color}>Patients</StatLabel>
        <Value color={color}>{population}</Value>
      </Section>
      <Section>
        <StatLabel color={color}>Invited</StatLabel>
        <Value color={color}>{voters}</Value>
      </Section>
      <Section>
        <StatLabel color={color}>Answered</StatLabel>
        <Value color={color}>{`${votes} (${turnout}%)`}</Value>
      </Section>
    </Flow>
  </AnalyticsPanel>
);
