import { createForm, Formm } from './Formm';
import styles from './styles.html?raw';

interface Document {
  element: HTMLElement;
}

export async function createDocument(content: HTMLElement, abortSignal: AbortSignal): Promise<Document> {
  const element = document.createElement('div');
  element.classList.add('sheets');
  element.innerHTML = styles;

  content.querySelectorAll(':scope > style').forEach(node => element.appendChild(node));

  let nextId = 0;

  const forms: Formm[] = [];

  for (const node of content.querySelectorAll(':scope > :not(script)')) {
    if (node instanceof HTMLElement && node.classList.contains('form')) {
      const form = await createForm(String('form-' + nextId++), node, abortSignal);

      if (abortSignal.aborted) {
        throw abortSignal.reason;
      }

      forms.push(form);

      element.appendChild(form.firstPage.element);
    }
  }

  content.querySelectorAll(':scope > script').forEach(node => element.appendChild(node));

  for (const textarea of element.querySelectorAll('textarea')) {
    const parent = textarea.parentElement;

    if (!parent) {
      continue;
    }

    const wrap = document.createElement('div');

    wrap.classList.add('grow-wrap');

    parent.insertBefore(wrap, textarea);

    wrap.appendChild(textarea);

    textarea.rows = 1;

    const handleInput = () => {
      wrap.dataset.replicatedValue = textarea.value;
    };

    textarea.addEventListener('input', handleInput);

    abortSignal.addEventListener('abort', () => {
      textarea.removeEventListener('input', handleInput);
    });

    wrap.setAttribute('data-replicated-value', textarea.value);

    textarea.dispatchEvent(
      new Event('input', {
        bubbles: true,
        cancelable: true,
      })
    );
  }

  const resizeObserver = new ResizeObserver(entries => {
    const formIds = new Set<string>();

    for (const entry of entries) {
      const formId = entry.target.parentElement?.dataset?.formId;

      if (formId) {
        formIds.add(formId);
      }
    }

    const activeElement = document.activeElement;

    for (const form of forms) {
      if (formIds.has(form.id)) {
        form.layOut();
      }
    }

    const headers = element.querySelectorAll('.page > .header');
    const numberOfHeaders = headers.length;

    headers.forEach((header, index) => {
      if (header instanceof HTMLElement) {
        header.innerText = `Page ${index + 1} of ${numberOfHeaders}`;
      }
    });

    if (activeElement && activeElement instanceof HTMLElement) {
      requestAnimationFrame(() => {
        activeElement.focus();
      });
    }
  });

  for (const part of element.querySelectorAll('.page > .contents > .form > *')) {
    resizeObserver.observe(part, { box: 'border-box' });
  }

  abortSignal.addEventListener('abort', () => {
    resizeObserver.disconnect();
  });

  return {
    element,
  };
}
