import React, { FormEvent, ReactNode, Reducer, useEffect, useReducer, useState } from 'react';
import {
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiAccountBoxMultiple } from '@mdi/js';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { Room } from '../../../../../types/Room';
import StaffShift from '../../../../../types/StaffShift';
import { Alert } from '@material-ui/lab';
import { gql, useMutation } from '@apollo/client';
import {
  startOfMonth,
  endOfMonth,
  differenceInWeeks,
  isSameWeek,
  addDays,
  subDays,
  isSameDay,
} from 'date-fns';

interface StaffShiftCopyButtonProps {
  date: any;
  rooms: Room[];
}

const StaffShiftCopyButton = ({ date, rooms }: StaffShiftCopyButtonProps) => {
  const [open, setOpen] = useState(false);

  const [copyStaffShiftRooms] = useMutation(gql`
    mutation copyStaffShiftRooms($ids: [Long!]!, $numberOfDays: Int!) {
      copyStaffShiftRooms(ids: $ids, numberOfDays: $numberOfDays)
    }
  `);

  const [copyStaffShiftRoomsWeekly] = useMutation(gql`
    mutation copyStaffShiftRoomsWeekly($ids: [Long!]!, $numberOfWeeks: Int!) {
      copyStaffShiftRoomsWeekly(ids: $ids, numberOfWeeks: $numberOfWeeks)
    }
  `);

  const [copyStaffShiftRoomsWeekdays] = useMutation(gql`
    mutation copyStaffShiftRoomsWeekdays($ids: [Long!]!, $numberOfWeeks: Int!) {
      copyStaffShiftRoomsWeekdays(ids: $ids, numberOfWeeks: $numberOfWeeks)
    }
  `);

  const [copyStaffShiftRoomsMonthly] = useMutation(gql`
    mutation copyStaffShiftRoomsMonthly($ids: [Long!]!, $numberOfMonths: Int!) {
      copyStaffShiftRoomsMonthly(ids: $ids, numberOfMonths: $numberOfMonths)
    }
  `);

  const [copyStaffShiftRoomsMonthlyFirst] = useMutation(gql`
    mutation copyStaffShiftRoomsMonthlyFirst($ids: [Long!]!, $numberOfMonths: Int!) {
      copyStaffShiftRoomsMonthlyFirst(ids: $ids, numberOfMonths: $numberOfMonths)
    }
  `);

  const [copyStaffShiftRoomsMonthlyLast] = useMutation(gql`
    mutation copyStaffShiftRoomsMonthlyLast($ids: [Long!]!, $numberOfMonths: Int!) {
      copyStaffShiftRoomsMonthlyLast(ids: $ids, numberOfMonths: $numberOfMonths)
    }
  `);

  const [numberOfDays, setNumberOfDays] = useState('');
  const [numberOfDaysError, setNumberOfDaysError] = useState('');

  const [copyType, setCopyType] = useState<'days' | 'weeks' | 'weekdays' | 'months' | 'firstWeekday' | 'lastWeekday'>(
    'days'
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!numberOfDays) {
      setNumberOfDaysError(`Number of following ${copyType} cannot be empty.`);
      return;
    }

    if (!numberOfDays.match(/^\d+$/)) {
      setNumberOfDaysError(`Number of following ${copyType} must be a natural number.`);
      return;
    }

    const n = Number(numberOfDays);
    if ((copyType === 'weeks' || copyType === 'weekdays') && n > 52) {
      setNumberOfDaysError('Number of weeks cannot be greater than 52.');
      return;
    }

    if (copyType === 'days' && n > 365) {
      setNumberOfDaysError('Number of days cannot be greater than 365.');
      return;
    }

    if (copyType === 'months' && n > 12) {
      setNumberOfDaysError('Number of months cannot be greater than 12.');
      return;
    }

    if (!state.selection) {
      return;
    }

    const ids = state.selection.roomSelections.flatMap(roomSelection =>
      roomSelection.staffShiftSelections.flatMap(staffShiftSelection =>
        staffShiftSelection.selected ? [staffShiftSelection.staffShift.id] : []
      )
    );

    let mutation;
    let variableKey;

    switch (copyType) {
      case 'days':
        mutation = copyStaffShiftRooms;
        variableKey = 'numberOfDays';
        break;
      case 'weeks':
        mutation = copyStaffShiftRoomsWeekly;
        variableKey = 'numberOfWeeks';
        break;
      case 'weekdays':
        mutation = copyStaffShiftRoomsWeekdays;
        variableKey = 'numberOfWeeks';
        break;
      case 'months':
        mutation = copyStaffShiftRoomsMonthly;
        variableKey = 'numberOfMonths';
        break;
      case 'lastWeekday':
        mutation = copyStaffShiftRoomsMonthlyLast;
        variableKey = 'numberOfMonths';
        break;
      case 'firstWeekday':
        mutation = copyStaffShiftRoomsMonthlyFirst;
        variableKey = 'numberOfMonths';
        break;
    }

    await mutation({
      variables: {
        ids,
        [variableKey]: n,
      },
    });

    setOpen(false);
  };

  const [state, dispatch] = useReducer(reduceEvents, { interactions: [] });

  const anyChecked = state.selection?.selected !== false;

  useEffect(() => {
    if (open) {
      dispatch({ type: 'Reset' });
    }
  }, [open]);

  useEffect(() => {
    dispatch({
      type: 'Restart',
      rooms,
    });
  }, [rooms]);

  const theme = useTheme();

  const getOrdinal = (n: number) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const firstDayOfMonth = startOfMonth(date);
  const lastDayOfMonth = endOfMonth(date);

  const weekOfMonth = differenceInWeeks(date, firstDayOfMonth) + 1;

  const isFirstWeek = isSameWeek(date, firstDayOfMonth);
  const isLastWeek = isSameWeek(date, lastDayOfMonth);

  const ordinalWeek = getOrdinal(weekOfMonth);

  const weekdayName = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(date);

  const getWeekdayOccurrence = (date: Date) => {
    let occurrence = 0;
    let current = startOfMonth(date);

    while (current <= date) {
      if (current.getDay() === date.getDay()) {
        occurrence++;
      }
      current = addDays(current, 1);
    }

    return occurrence;
  };

  const weekdayOccurrence = getWeekdayOccurrence(date);
  const showMonthlyOption = weekdayOccurrence >= 2 && weekdayOccurrence <= 4;

  const getFirstOccurrenceOfWeekday = (date: Date) => {
    const firstDay = startOfMonth(date);
    let firstOccurrence = firstDay;

    while (firstOccurrence.getDay() !== date.getDay()) {
      firstOccurrence = addDays(firstOccurrence, 1);
    }

    return firstOccurrence;
  };

  const isFirstOccurrenceOfWeekday = isSameDay(date, getFirstOccurrenceOfWeekday(date));

  const getLastOccurrenceOfWeekday = (date: Date) => {
    const lastDay = endOfMonth(date);
    let lastOccurrence = lastDay;

    while (lastOccurrence.getDay() !== date.getDay()) {
      lastOccurrence = subDays(lastOccurrence, 1);
    }

    return lastOccurrence;
  };

  const isLastOccurrenceOfWeekday = isSameDay(date, getLastOccurrenceOfWeekday(date));

  return (
    <>
      <Tooltip title="Staff Shift Duplication">
        <IconButton onClick={() => setOpen(true)} color="primary">
          <Icon path={mdiAccountBoxMultiple} size={1} />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
        <DialogTitle>
          Apply This Day’s Staff Members to the Following{' '}
          {copyType === 'firstWeekday' || copyType === 'lastWeekday' ? 'months' : copyType}
        </DialogTitle>

        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              <Typography variant="body2" gutterBottom>
                Select staff members and enter the number of future{' '}
                {copyType === 'firstWeekday' || copyType === 'lastWeekday' ? 'months' : copyType} to apply them to.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Excluding weekends. Existing assignments will not be altered.
              </Typography>
            </DialogContentText>
            {state.rooms ? (
              state.selection ? (
                <>
                  <Box mt={4} mb={4}>
                    <FormControl fullWidth>
                      <Select value={copyType} onChange={e => setCopyType(e.target.value as any)}>
                        <MenuItem value="days">Days</MenuItem>
                        <MenuItem value="weeks">Weeks</MenuItem>
                        <MenuItem value="weekdays">Every weekday &#40;Monday to Friday&#41;</MenuItem>
                        {showMonthlyOption && (
                          <MenuItem value="months">
                            Monthly on the {ordinalWeek} {weekdayName}
                          </MenuItem>
                        )}
                        {isLastOccurrenceOfWeekday && (
                          <MenuItem value="lastWeekday">Monthly on the last {weekdayName}</MenuItem>
                        )}
                        {isFirstOccurrenceOfWeekday && (
                          <MenuItem value="firstWeekday">Monthly on the first {weekdayName}</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <TextField
                    error={Boolean(numberOfDaysError)}
                    helperText={numberOfDaysError}
                    variant="filled"
                    type="number"
                    label={`Number of ${
                      copyType === 'firstWeekday' || copyType === 'lastWeekday' ? 'months' : copyType
                    }`}
                    autoFocus
                    fullWidth
                    value={numberOfDays}
                    onChange={e => setNumberOfDays(e.target.value)}
                    onFocus={() => setNumberOfDaysError('')}
                  />

                  <Selector
                    name="All staff members"
                    selected={state.selection.selected}
                    onSelect={() => dispatch({ type: 'Interact', interaction: { type: 'SelectAll' } })}
                    onDeselect={() => dispatch({ type: 'Interact', interaction: { type: 'DeselectAll' } })}
                  >
                    {state.selection.roomSelections.map(({ selected, room, staffShiftSelections }) => (
                      <Selector
                        key={room.id}
                        name={room.name}
                        selected={selected}
                        onSelect={() =>
                          dispatch({ type: 'Interact', interaction: { type: 'SelectRoom', roomId: room.id } })
                        }
                        onDeselect={() =>
                          dispatch({ type: 'Interact', interaction: { type: 'DeselectRoom', roomId: room.id } })
                        }
                      >
                        {staffShiftSelections.map(({ selected, staffShift }) =>
                          staffShift.staff ? (
                            <Selector
                              key={staffShift.id}
                              name={staffShift.staff.name}
                              selected={selected}
                              onSelect={() =>
                                dispatch({
                                  type: 'Interact',
                                  interaction: { type: 'SelectStaffShift', staffShiftId: staffShift.id },
                                })
                              }
                              onDeselect={() =>
                                dispatch({
                                  type: 'Interact',
                                  interaction: { type: 'DeselectStaffShift', staffShiftId: staffShift.id },
                                })
                              }
                            />
                          ) : null
                        )}
                      </Selector>
                    ))}
                  </Selector>
                </>
              ) : (
                <Alert severity="error">There are no staff shifts to apply.</Alert>
              )
            ) : (
              <Typography>Loading…</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button type="reset" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button type="submit" disabled={!anyChecked} color="primary">
              Apply
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default StaffShiftCopyButton;

interface State {
  rooms?: Room[];
  selection?: Selection;
  interactions: Interaction[];
}

interface Selection {
  selected: boolean | null;
  roomSelections: RoomSelection[];
}

interface RoomSelection {
  selected: boolean | null;
  room: Room;
  staffShiftSelections: StaffShiftSelection[];
}

interface StaffShiftSelection {
  selected: boolean;
  staffShift: StaffShift;
}

type Interaction = SelectAll | SelectRoom | SelectStaffShift | DeselectAll | DeselectRoom | DeselectStaffShift;

interface SelectAll {
  type: 'SelectAll';
}

interface SelectRoom {
  type: 'SelectRoom';
  roomId: any;
}

interface SelectStaffShift {
  type: 'SelectStaffShift';
  staffShiftId: any;
}

interface DeselectAll {
  type: 'DeselectAll';
}

interface DeselectRoom {
  type: 'DeselectRoom';
  roomId: any;
}

interface DeselectStaffShift {
  type: 'DeselectStaffShift';
  staffShiftId: any;
}

type Action = Reset | Restart | Interact;

interface Reset {
  type: 'Reset';
}

interface Restart {
  type: 'Restart';
  rooms: Room[];
}

interface Interact {
  type: 'Interact';
  interaction: Interaction;
}

const reduceEvents: Reducer<State, Action> = (prevState, action) => {
  switch (action.type) {
    case 'Reset': {
      if (prevState.rooms) {
        return {
          rooms: prevState.rooms,
          selection: createSelection(prevState.rooms),
          interactions: [],
        };
      }

      return { interactions: [] };
    }
    case 'Restart': {
      const initialSelection = createSelection(action.rooms);

      if (initialSelection) {
        return {
          rooms: action.rooms,
          selection: prevState.interactions.reduce(applyInteraction, initialSelection),
          interactions: prevState.interactions,
        };
      }

      return {
        rooms: action.rooms,
        interactions: prevState.interactions,
      };
    }
    case 'Interact': {
      const interactions = [...prevState.interactions, action.interaction];
      return {
        rooms: prevState.rooms,
        selection: prevState.selection ? applyInteraction(prevState.selection, action.interaction) : undefined,
        interactions,
      };
    }
    default:
      return prevState;
  }
};

const applyInteraction = (selection: Selection, interaction: Interaction): Selection => {
  switch (interaction.type) {
    case 'SelectAll':
      return {
        selected: true,
        roomSelections: selection.roomSelections.map(({ room, staffShiftSelections }) => ({
          selected: true,
          room,
          staffShiftSelections: staffShiftSelections.map(({ staffShift }) => ({
            selected: true,
            staffShift,
          })),
        })),
      };
    case 'SelectRoom': {
      const roomSelections = selectRoom(selection.roomSelections, interaction.roomId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    case 'SelectStaffShift': {
      const roomSelections = selectStaffShift(selection.roomSelections, interaction.staffShiftId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    case 'DeselectAll':
      return {
        selected: false,
        roomSelections: selection.roomSelections.map(({ room, staffShiftSelections }) => ({
          selected: false,
          room,
          staffShiftSelections: staffShiftSelections.map(({ staffShift }) => ({
            selected: false,
            staffShift,
          })),
        })),
      };
    case 'DeselectRoom': {
      const roomSelections = deselectRoom(selection.roomSelections, interaction.roomId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    case 'DeselectStaffShift': {
      const roomSelections = deselectStaffShift(selection.roomSelections, interaction.staffShiftId);
      return {
        selected:
          roomSelections.every(roomSelection => roomSelection.selected === true) ||
          (roomSelections.every(roomSelection => roomSelection.selected === false) ? false : null),
        roomSelections,
      };
    }
    default:
      return selection;
  }
};

const selectRoom = (roomSelections: RoomSelection[], roomId: any): RoomSelection[] =>
  roomSelections.map(({ selected, room, staffShiftSelections }) => ({
    selected: room.id === roomId ? true : selected,
    room,
    staffShiftSelections:
      room.id === roomId
        ? staffShiftSelections.map(({ selected, staffShift }) => ({
            selected: true,
            staffShift,
          }))
        : staffShiftSelections,
  }));

const selectStaffShift = (roomSelections: RoomSelection[], staffShiftId: any): RoomSelection[] =>
  roomSelections.map(({ room, staffShiftSelections: prevStaffShiftSelections }) => {
    const staffShiftSelections = prevStaffShiftSelections.map(({ selected, staffShift }) => ({
      selected: staffShift.id === staffShiftId ? true : selected,
      staffShift,
    }));

    return {
      selected:
        staffShiftSelections.every(staffShiftSelection => staffShiftSelection.selected) ||
        (staffShiftSelections.every(staffShiftSelection => !staffShiftSelection.selected) ? false : null),
      room,
      staffShiftSelections,
    };
  });

const deselectRoom = (roomSelections: RoomSelection[], roomId: any): RoomSelection[] =>
  roomSelections.map(({ selected, room, staffShiftSelections }) => ({
    selected: room.id === roomId ? false : selected,
    room,
    staffShiftSelections:
      room.id === roomId
        ? staffShiftSelections.map(({ selected, staffShift }) => ({
            selected: false,
            staffShift,
          }))
        : staffShiftSelections,
  }));

const deselectStaffShift = (roomSelections: RoomSelection[], staffShiftId: any): RoomSelection[] =>
  roomSelections.map(({ selected, room, staffShiftSelections: prevStaffShiftSelections }) => {
    const staffShiftSelections = prevStaffShiftSelections.map(({ selected, staffShift }) => ({
      selected: staffShift.id === staffShiftId ? false : selected,
      staffShift,
    }));

    return {
      selected:
        staffShiftSelections.every(staffShiftSelection => staffShiftSelection.selected) ||
        (staffShiftSelections.every(staffShiftSelection => !staffShiftSelection.selected) ? false : null),
      room,
      staffShiftSelections,
    };
  });

const createSelection = (rooms: Room[]): Selection | undefined => {
  const roomSelections = rooms
    .map(room => ({
      selected: true,
      room,
      staffShiftSelections: room.staffShifts
        .filter(staffShift => staffShift.staff)
        .map(staffShift => ({
          selected: true,
          staffShift,
        })),
    }))
    .filter(room => room.staffShiftSelections.length > 0);

  if (roomSelections.length > 0) {
    return {
      selected: true,
      roomSelections,
    };
  }

  return undefined;
};

interface SelectorProps {
  name: string;
  selected: boolean | null;
  onSelect: () => void;
  onDeselect: () => void;
  children?: ReactNode;
}

const Selector = ({ name, selected, onSelect, onDeselect, children }: SelectorProps) => (
  <Box>
    <FormControlLabel
      control={
        <Checkbox
          checked={Boolean(selected)}
          indeterminate={selected === null}
          onChange={e => (e.target.checked ? onSelect() : onDeselect())}
        />
      }
      label={name}
    />
    <Box ml={2}>{children}</Box>
  </Box>
);
