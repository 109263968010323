import React, { ReactNode } from 'react';
import { useValue } from '../components/entities/surgeonProcedures/predefinedForms/ValueContext';
import { useQuestionnaireValueViaSelector } from '../components/entities/surgeonProcedures/predefinedForms/hooks';

interface ListProps {
  rowspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  name: string;
  questionnaire?: (questionnaire: unknown, questionnaireValue: unknown) => unknown[];
  children: (length: number) => ReactNode;
}

const List = ({ rowspan, colspan, name, questionnaire, children }: ListProps) => {
  const { defaultValue, value, setValue } = useValue(name);

  const questionnaireValue = useQuestionnaireValueViaSelector(questionnaire);

  const actualValue = value ?? (questionnaireValue || defaultValue || []);

  const length = Array.isArray(actualValue) ? actualValue.length : 0;

  return <>{children(length)}</>;
};

export default List;
