import React, { ReactNode } from 'react';

export interface CellProps {
  rowspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  colspan?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  children?: ReactNode;
}

const Cell = ({ rowspan, colspan, children }: CellProps) => (
  <div className={`f-span-${colspan ?? 12}`} style={{ gridRow: rowspan ? `auto / span ${rowspan}` : undefined }}>
    {children}
  </div>
);

export interface LabeledCellProps extends CellProps {
  label?: string;
}
export const LabeledCell = ({ label, colspan, rowspan, children}: LabeledCellProps) => (
  (<Cell colspan={colspan} rowspan={rowspan}>{!!label ? `${label} ` : ''}{children}</Cell>)
)

export default Cell;
