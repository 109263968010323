import React, { FC, Fragment } from 'react';
import MultiSelectInput from '../../../se/components/inputs/MultiSelectInput';

const PatientTypSelectInput: FC<any> = ({ value, onChange }) => {
  const options = [
    { label: 'Out', value: 'Out' },
    { label: 'In', value: 'In' },
    { label: 'Emergency', value: 'Emergency' },
    { label: 'ICU', value: 'ICU' },
  ];

  const transformedValue = options.filter(e => (value || []).includes(e.value));

  return (
    <Fragment>
      <MultiSelectInput
        name="patient-type-select"
        options={options}
        onChange={updated => {
          onChange(updated?.map(e => e.value));
        }}
        value={transformedValue}
        loading={false}
        disabled={false}
      />
    </Fragment>
  );
};

export default PatientTypSelectInput;
