import { createPage, Page } from './Page';

export interface Formm {
  id: string;
  firstPage: Page;
  layOut: () => void;
}

export async function createForm(id: string, content: HTMLElement, abortSignal: AbortSignal): Promise<Formm> {
  content.dataset.formId = id;

  const firstPage = await createPage(content, abortSignal);

  const segments = [...content.querySelectorAll<HTMLElement>(':scope > *')];

  const layOut = async () => {
    const contentsHeight = firstPage.element.querySelector<HTMLElement>(':scope > .contents')!.offsetHeight;

    interface Group {
      height: number;
      segments: HTMLElement[];
    }

    const groups: Group[] = [];

    let currentGroup: Group | undefined;

    for (const segment of segments) {
      if (segment instanceof HTMLElement) {
        const segmentHeight = segment.classList.contains('spacer') ? 0 : segment.offsetHeight;

        if (!currentGroup || currentGroup.height + segmentHeight > contentsHeight) {
          currentGroup = {
            height: 0,
            segments: [],
          };

          groups.push(currentGroup);
        }

        currentGroup.height += segmentHeight;
        currentGroup.segments.push(segment);
      }
    }

    let currentPage = firstPage;

    const getCurrentFormElement = () => currentPage.element.querySelector<HTMLElement>(':scope > .contents > .form')!;

    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      const nextGroup = groups[i + 1];

      const currentFormElement = getCurrentFormElement();

      for (const segment of group.segments) {
        if (segment.parentElement === currentFormElement) {
          continue;
        }

        currentFormElement.appendChild(segment);
      }

      if (nextGroup) {
        if (currentPage.nextPage) {
          currentPage = currentPage.nextPage;
        } else {
          const form = document.createElement('div');
          form.classList.add('form');
          form.dataset.formId = id;
          const nextPage = await createPage(form, abortSignal);
          if (currentPage.element.nextSibling) {
            currentPage.element.parentElement!.insertBefore(nextPage.element, currentPage.element.nextSibling);
          } else {
            currentPage.element.parentElement!.appendChild(nextPage.element);
          }
          currentPage.nextPage = nextPage;
          currentPage = nextPage;
        }
      } else {
        if (currentPage.nextPage) {
          currentPage.nextPage.destroy();
          currentPage.nextPage = undefined;
        }
      }
    }
  };

  return {
    id,
    firstPage,
    layOut,
  };
}
