import React, { useState } from 'react';
import styled from 'styled-components';
import { withTheme } from '../../../../theme';
import { withDate } from '../../../HospitalInfo';
import { differenceInSeconds } from 'date-fns';
import { Icon } from '../../../Panel/PanelStatus';

const Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10em;
  font-family: monospace;
  font-weight: 500;
  color: ${({ color }) => color};
`;

export const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const Label = styled.div`
  opacity: 0.75;
  font-size: 4em;
  color: ${withTheme(theme => theme.textColor.string())};
`;

const leftPad = val => (String(val).length < 2 ? `0${val}` : val);

const formatDuration = seconds => {
  const hrs = Math.floor(seconds / 3600);
  const mnt = Math.floor((seconds - hrs * 3600) / 60);
  const sec = seconds % 60;
  return [leftPad(hrs), leftPad(mnt), leftPad(sec)].join(':');
};

const withSeconds = withDate(1000);

const TurnoverClock = props => {
  const [cleaningStartedAtDefault] = useState(new Date());

  const elapsedSeconds = differenceInSeconds(props.date, props?.room?.cleaningStartedAt || cleaningStartedAtDefault);

  const warningThreshold = props?.warningThreshold != null ? props.warningThreshold * 60 : null;
  const alertThreshold = props?.alertThreshold != null ? props.alertThreshold * 60 : null;

  let timerColor = 'white';

  if (alertThreshold !== null && elapsedSeconds >= alertThreshold) {
    timerColor = 'red';
  } else if (warningThreshold !== null && elapsedSeconds >= warningThreshold) {
    timerColor = 'gold';
  }

  return (
    <>
      {isSameDate(new Date(props?.room?.cleaningStartedAt), new Date()) ? (
        <>
          <Label>Turnover</Label>
          <Timer color={timerColor}>{formatDuration(elapsedSeconds)}</Timer>
        </>
      ) : (
        <Icon src={props.src} compactView={props.compactView} miniView={props.miniView} />
      )}
    </>
  );
};

export default withSeconds(TurnoverClock);
