import React, { ReactNode, useRef, useLayoutEffect, useState } from 'react';
import { useUpdateHeaders } from './FormV2Context';

interface FormProps {
  title?: ReactNode;
  children?: ReactNode;
}

const Form = ({ title, children }: FormProps) => {
  const updateHeaders = useUpdateHeaders();

  const sheetsRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const trackingRef = useRef<Tracking>({});

  const [sheets, setSheets] = useState(2);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const sheets = sheetsRef.current;

      if (!sheets) {
        return;
      }

      const root = rootRef.current;

      if (!root) {
        return;
      }

      const segments = root.querySelectorAll<HTMLElement>(':scope > :not(.layout)');

      const existingSheets = sheets.querySelectorAll<HTMLDivElement>(':scope > *');

      let currentSheet = -1;
      let currentSegment = 0;
      let remainingHeight = 0;

      // Let’s go through the segments and see where to fit them
      while (currentSegment < segments.length) {
        const segment = segments[currentSegment];
        const segmentHeight = segment.offsetHeight;

        // If there is not enough space for the segment, we need to go to a next sheet
        if (remainingHeight < segmentHeight) {
          currentSheet++;

          const existingSheet = existingSheets[currentSheet];

          const sheet = existingSheet ?? document.createElement('div');
          const header = sheet.querySelector<HTMLDivElement>(`:scope > .header`) ?? document.createElement('div');
          const contents = sheet.querySelector<HTMLDivElement>(`:scope > .contents`) ?? document.createElement('div');
          const footer = sheet.querySelector<HTMLDivElement>(`:scope > .footer`) ?? document.createElement('div');

          // If the sheet does not exist, we need to create it
          if (!existingSheet) {
            sheet.style.width = 'var(--paper-width)';
            sheet.style.height = 'var(--paper-height)';
            sheet.style.background = 'white';
            sheet.style.padding = 'var(--paper-margin)';
            sheet.style.display = 'flex';
            sheet.style.flexDirection = 'column';
            sheet.style.alignItems = 'stretch';
            sheet.style.gap = 'var(--contents-gap)';

            const left = document.createElement('div');
            const center = document.createElement('div');
            const right = document.createElement('div');

            left.className = 'left';
            center.className = 'center';
            right.className = 'right';

            header.appendChild(left);
            header.appendChild(center);
            header.appendChild(right);

            // header.style.background = 'rgba(255 0 0 / 10%)';
            // contents.style.background = 'rgba(0 0 255 / 10%)';
            // footer.style.background = 'rgba(255 0 0  / 10%)';

            header.className = `header`;
            contents.className = `contents`;
            footer.className = `footer`;

            contents.style.flex = '1';

            sheet.appendChild(header);
            sheet.appendChild(contents);
            sheet.appendChild(footer);

            sheets.appendChild(sheet);
          }

          // If there is a previous segment, we need to add a page break
          if (currentSegment > 0) {
            const previousElement = segment.previousElementSibling as HTMLElement;
            const previousPageBreak = previousElement?.classList.contains('page-break') ? previousElement : undefined;
            const pageBreak = previousPageBreak ?? document.createElement('div');

            if (!previousPageBreak) {
              pageBreak.className = 'page-break layout';
            }

            const headerHeight = header.offsetHeight > 0 ? `${header.offsetHeight}px + var(--contents-gap)` : '0';

            pageBreak.style.flex = `0 0 calc(${remainingHeight}px + ${headerHeight} + var(--paper-gap) + 2 * var(--paper-margin))`;
            pageBreak.style.height = `calc(${remainingHeight}px + ${headerHeight} + var(--paper-gap) + 2 * var(--paper-margin))`;

            root.insertBefore(pageBreak, segment);
          } else {
            // If there is a header, we need to add an offset; otherwise, we need to remove it
            if (header.offsetHeight > 0) {
              const previousElement = segment.previousElementSibling as HTMLElement;
              const previousOffset = previousElement?.classList.contains('offset') ? previousElement : undefined;
              const offset = previousOffset ?? document.createElement('div');

              if (!previousOffset) {
                offset.className = 'offset layout';
              }

              offset.style.flex = `0 0 calc(${header.offsetHeight}px + var(--contents-gap))`;
              offset.style.height = `calc(${header.offsetHeight}px + var(--contents-gap))`;

              root.insertBefore(offset, segment);
            } else {
              const previousElement = segment.previousElementSibling as HTMLElement;
              const previousOffset = previousElement?.classList.contains('offset') ? previousElement : undefined;
              previousOffset?.remove();
            }
          }

          currentSegment++;

          remainingHeight = contents.offsetHeight - segmentHeight;

          continue;
        }

        const previousElement = segment.previousElementSibling as HTMLElement;
        const previousPageBreak = previousElement?.classList.contains('page-break') ? previousElement : undefined;

        previousPageBreak?.remove();

        currentSegment++;

        remainingHeight -= segmentHeight;
      }

      currentSheet++;

      const sheetsToRemove: HTMLElement[] = [];

      while (existingSheets[currentSheet]) {
        sheetsToRemove.push(existingSheets[currentSheet]);
        currentSheet++;
      }

      for (const sheet of sheetsToRemove) {
        sheet.remove();
      }

      updateHeaders();
    });

    trackingRef.current.resizeObserver = resizeObserver;

    return () => resizeObserver.disconnect();
  }, []);

  useLayoutEffect(() => {
    const resizeObserver = trackingRef.current.resizeObserver;

    if (!resizeObserver) {
      return;
    }

    const sections = new Set(rootRef.current?.querySelectorAll(':scope > *') ?? []);

    for (const header of sheetsRef.current?.querySelectorAll('.header') ?? []) {
      sections.add(header);
    }

    const trackedElements = trackingRef.current.trackedElements ?? (trackingRef.current.trackedElements = new Set());

    const stopTracking = difference(trackedElements, sections);
    const startTracking = difference(sections, trackedElements);

    for (const element of stopTracking) {
      resizeObserver.unobserve(element);
      trackedElements.delete(element);
    }

    for (const element of startTracking) {
      resizeObserver.observe(element, { box: 'border-box' });
      trackedElements.add(element);
    }
  });

  return (
    <div style={{ display: 'grid' }}>
      <div
        ref={sheetsRef}
        style={{
          gridRow: '1 / span 1',
          gridColumn: '1 / span 1',
          display: 'flex',
          flexDirection: 'column',
          gap: 'var(--paper-gap)',
        }}
      />
      <div
        ref={rootRef}
        style={{
          gridRow: '1 / span 1',
          gridColumn: '1 / span 1',
          padding: 'var(--paper-margin)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {title && <h1 className="f-title f-center">{title}</h1>}
        {children}
      </div>
    </div>
  );
};

export default Form;

interface Tracking {
  resizeObserver?: ResizeObserver;
  trackedElements?: Set<Element>;
}

function difference(a: Set<Element>, b: Set<Element>): Set<Element> {
  const result = new Set<Element>();

  for (const element of a) {
    if (!b.has(element)) {
      result.add(element);
    }
  }

  return result;
}
