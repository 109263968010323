import { Box, Typography, useTheme } from '@material-ui/core';
import { valueNames } from '../PieCharts';
import React from 'react';

export const ChartLegend = props => {
  const theme = useTheme();
  const { payload } = props;

  return (
    <ul
      style={{
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
      }}
    >
      {payload.map((entry, index) => (
        <Box key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
          <Box style={{ width: 12, height: 12, backgroundColor: entry.color, borderRadius: '100%' }} />
          <Typography variant="caption" style={{ fontWeight: 500, lineHeight: 1 }}>
            {valueNames[entry.value] || entry.value}
          </Typography>
        </Box>
      ))}
    </ul>
  );
};

export default ChartLegend;
