import React, { createElement, useCallback, useRef } from 'react';
import FormV2Context from './FormV2Context';

export const FormV2 = form => props => {
  const sheetsRef = useRef<HTMLDivElement>(null);

  const updateHeaders = useCallback(() => {
    const sheets = sheetsRef.current;

    if (!sheets) {
      return;
    }

    const headers = sheets.querySelectorAll<HTMLElement>('.header');

    const n = headers.length;

    for (let i = 0; i < n; i++) {
      const header = headers[i];
      const left = header.querySelector<HTMLElement>(':scope > .left');
      const center = header.querySelector<HTMLElement>(':scope > .center');

      if (left) left.innerText = `Page ${i + 1} of ${n}`;

      if (center) center.innerText = `${form.title}`;
    }
  }, []);

  return (
    <>
      <style>{`
        /* --- Global Reset & Defaults --- */

        /* Box sizing rules */
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        /* Prevent font size inflation */
        html {
          -moz-text-size-adjust: none;
          -webkit-text-size-adjust: none;
          text-size-adjust: none;
        }

        /* Remove default margin in favour of better control in authored CSS */
        body, h1, h2, h3, h4, p,
        figure, blockquote, dl, dd {
          margin-block-start: 0;
          margin-block-end: 0;
        }

        /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
        ul[role='list'],
        ol[role='list'] {
          list-style: none;
        }

        /* Set core body defaults */
        body {
          min-height: 100vh;
          line-height: 1.5;
        }

        /* Set shorter line heights on headings and interactive elements */
        h1, h2, h3, h4,
        button, input, label {
          line-height: 1.1;
        }

        /* Balance text wrapping on headings */
        h1, h2,
        h3, h4 {
          text-wrap: balance;
        }

        /* A elements that don't have a class get default styles */
        a:not([class]) {
          text-decoration-skip-ink: auto;
          color: currentColor;
        }

        /* Make images easier to work with */
        img,
        picture {
          max-width: 100%;
          display: block;
        }

        /* Inherit fonts for inputs and buttons */
        input, button,
        textarea, select {
          font-family: inherit;
          font-size: inherit;
          background-color: transparent;
        }

        textarea {
          padding-top: 1px;
        }

        /* Make sure textareas without a rows attribute are not tiny */
        textarea:not([rows]) {
          min-height: 10em;
        }

        /* Anything that has been anchored to should have extra scroll margin */
        :target {
          scroll-margin-block: 5ex;
        }

        /* --- Font Sizes & Base Styles --- */

        html, body {
          font-size: 0.125in;  /* Base font size */
        }

        h1 { font-size: 1.25rem; }
        h2 { font-size: 1.2rem; }
        h3 { font-size: 1.15rem; }
        h4 { font-size: 1.1rem; }
        h5 { font-size: 1.05rem; }
        h6 { font-size: 1rem; }

        /* --- CSS Variables (Root Level) --- */
        :root {
          --paper-width: 8.5in;
          --paper-height: 11in;
          --paper-margin: 0.25in; /*  Used for padding on .page */
          --paper-gap: 0.125in;
          --contents-gap: 0.125in;
          --cell-v-padding: calc(0.0625in / 2);  /*  Used in f-section */
          --cell-h-padding: calc(0.125in / 2);  /*  Used in f-section */
          --checkbox-height: 1em; /* Used by checkmark*/
          --background-color: #fff; /* Used by checkmark */
          --density-comfy-padding: 2px;
          --density-compact-padding: 1px;
        }


        /* --- Sheets & Page Structure --- */

        .sheets {
          display: flex;
          flex-direction: column;
          gap: var(--paper-gap, 0); /* Default to 0, controlled by media query */
        }

        .page {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          padding: var(--paper-margin);
          width: var(--paper-width);
          height: var(--paper-height);
          background-color: white;  /*  Keep background for web */
          overflow: hidden;
          position: relative;
            /* Remove box-shadow via media query */
            box-shadow: 0 0.0625in 0.125in rgba(0, 0, 0, 0.2);
        }


        .header:empty, .footer:empty {
          display: none;
        }

        .header {
          display: grid;
          grid-template-columns: 1fr auto 1fr;
        }

        .form {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: stretch;
        }

        .spacer {
          flex-grow: 1;
        }


        /* --- Form Component Styles --- */
          /*(These styles seem to be component-specific and should be consistent)*/

        .grow-wrap {
          display: grid;
          flex: 1;
        }

        .grow-wrap::after {
          content: attr(data-replicated-value) ' ';
          white-space: pre-wrap;
          visibility: hidden;
        }

        .grow-wrap > textarea {
          resize: none;
          overflow: hidden;
        }

        .grow-wrap > textarea,
        .grow-wrap::after {
          border: none;
          padding: calc(1in * pow(1 / 2, 5));
          padding-top: calc(1in * pow(1 / 2, 5) + 1px);
          font: inherit;
          line-height: 1.4;
          font-family: sans-serif;
          word-break: break-word;
          grid-area: 1 / 1 / 2 / 2;
        }

        .grow-wrap > textarea:focus {
          outline: none;
        }


        .checkmark {
            box-sizing: border-box;
            --background-color: #fff;
            --checkbox-height: 1em;
            --checkbox-width: 1em;
        }

        .checkmark input[type='checkbox'] {
          display: none;
        }

      .checkmark > input[type='checkbox'] + span {
        flex-shrink: 0;height: var(--checkbox-height);
        width: var(--checkbox-height);
        background-color: transparent;
        border: 1px solid black;
        position: relative;
        top: 0.133em;
        margin: calc(1in * pow(1 / 2, 5));
        margin-left: 0;
        display: inline-block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -moz-transition: border-color ease 0.2s;
        -o-transition: border-color ease 0.2s;
        -webkit-transition: border-color ease 0.2s;
        transition: border-color ease 0.2s;
        cursor: pointer;
      }
      .checkmark > input[type='checkbox'] + span::before,
      .checkmark > input[type='checkbox'] + span::after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: absolute;
        height: 0;
        width: calc(1in * pow(1 / 2, 6));
        background-color: black;
        display: inline-block;
        -moz-transform-origin: left top;
        -ms-transform-origin: left top;
        -o-transform-origin: left top;
        -webkit-transform-origin: left top;
        transform-origin: left top;
        border-radius: 5px;
        content: ' ';
        -webkit-transition: opacity ease 0.5s;
        -moz-transition: opacity ease 0.5s;
        transition: opacity ease 0.5s;
      }
      .checkmark > input[type='checkbox'] + span::before {
        top: calc(var(--checkbox-height) * 0.72);
        left: calc(var(--checkbox-height) * 0.41);
        box-shadow: 0 0 0 calc(var(--checkbox-height) * 0.05)
          var(--background-color);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
      .checkmark > input[type='checkbox'] + span::after {
        top: calc(var(--checkbox-height) * 0.37);
        left: calc(var(--checkbox-height) * 0.05);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      .checkmark input[type='checkbox']:checked + span::after {
        height: calc(var(--checkbox-height) / 2);
      }
      .checkmark input[type='checkbox']:checked + span::before {
        height: calc(var(--checkbox-height) * 1.2);
      }

      /* --- f- classes (Looks like a utility/layout system) --- */
        h1 {
          margin: var(--cell-v-padding) var(--cell-h-padding);
          font-weight: bold;
        }

        .f-section.outlined {
          border-top: 1px solid black;
          border-left: 1px solid black;
        }

        .f-section {
          align-items: start;
        }

        .f-section:not(.outlined) {
          --cell-v-padding: 0;
          --cell-h-padding: 0;
        }

        .f-section > label {
          padding-top: var(--cell-v-padding);
          padding-bottom: var(--cell-v-padding);
        }

        .f-section > h2:first-child {
          display: block;padding-top: calc(var(--cell-v-padding) + 1px);
        }

        .f-section + .f-section.outlined {
          border-top: none;
        }

        .f-section > * {
          padding: var(--cell-v-padding) var(--cell-h-padding);
          display: flex;
          min-width: 0;
          align-items: baseline;
        }

        .f-section.outlined > * {
          border-bottom: 1px solid black;
          border-right: 1px solid black;
        }

        .f-center {
          text-align: center;
        }

        .f-cols-12 {
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          align-items: stretch;
        }

        .f-uppercase {
          text-transform: uppercase;
        }

        /*  Grid Spans */
        .f-span-12 { grid-column: span 12; }
        .f-span-11 { grid-column: span 11; }
        .f-span-10 { grid-column: span 10; }
        .f-span-9 {  grid-column: span 9;  }
        .f-span-8 {  grid-column: span 8;  }
        .f-span-7 {  grid-column: span 7;  }
        .f-span-6 {  grid-column: span 6;  }
        .f-span-5 {  grid-column: span 5;  }
        .f-span-4 {  grid-column: span 4;  }
        .f-span-3 {  grid-column: span 3;  }
        .f-span-2 {  grid-column: span 2;  }
        .f-span-1 {  grid-column: span 1;  }

        .f-bg-light {
          background: rgba(0 0 0 / 5%);
        }

        .f-flex {
          display: flex;
         }

        .f-flex-col {
          display: flex;
          flex-direction: column;
          align-items: stretch;
        }

        .f-label-text {
          margin-right: .25rem;
        }

        .smaller-font textarea {
          font-size: 11px;
        }

        /* --- Dense layouts in 2 steps - compact and comfy --- */
        .dense {
          --dense-padding: var(--density-compact-padding, 1px);
          line-height: 1;
        }

        .dense-comfy {
          --dense-padding: var(--density-comfy-padding, 2px);
          line-height: 1;
        }

        /* Then apply to both in a single pass */
        .dense .grow-wrap,
        .dense-comfy .grow-wrap,
        .dense .grow-wrap::after,
        .dense-comfy .grow-wrap::after,
        .dense [class^="f-span-"],
        .dense-comfy [class^="f-span-"],
        .dense textarea,
        .dense-comfy textarea {
          padding: var(--dense-padding);
          line-height: 1;
        }

        .dense textarea,
        .dense-comfy textarea {
          padding: 0;
        }


      /* --- Print Styles (Media Query) --- */

        @media print {
         :root {
         --paper-gap: 0;
         }
          /* Remove web-specific styles */
          .page {
            background-color: transparent; /*  No background in print */
            box-shadow: none;            /*  No shadow in print */
            width: 100%;                /* Use full width */
            height: auto;               /* Let height be automatic */
            padding: 0;          /* Reset padding.  Margins are set on @page */
          }

          .sheets {
              /*  Remove the gap between pages */
          }

          /* Page break control */
          .form > * {
            page-break-inside: avoid;
          }

          /* @page rule for printer settings */
          @page {
            size: letter;
            margin: 0; /*  Control margins here */
          }
        }

   .f-checkbox-centered {
      align-items: center;
      justify-content: center;
  }
`}</style>
      <div ref={sheetsRef} className="sheets">
        <FormV2Context.Provider value={{ updateHeaders }}>{createElement(form, props)}</FormV2Context.Provider>
      </div>
    </>
  );
};
