import React, { FC } from 'react';
import GraphLayout from '../analytics/GraphLayout';
import HospitalRating from '../analytics/HospitalRating';
import PWAInstallBanner from '../../../se/components/PWAInstallBanner';
import { analyticsConfigQuery, dashboardQuery, dashboardQueryPrev } from '../../../graph/dashboard';
import { useQuery } from '@apollo/client';
import pick from 'lodash/fp/pick';
import { AnalyticsLayout } from '../Analytics';
import ProcedureCancellationChart from './v2/ProcedureCancellationChart';
import PatientJourneyChart from './v2/PatientJourneyChart';
import ORPerformanceChart from './v2/ORPerformanceChart';
import AnalyticsPanel from './components/AnalyticsPanel';

const pickFilter = pick(['physician', 'procedureType', 'dateRange']);

const GeneralAnalytics: FC<{ filter: any; setFilter: any; isBusinessManager: boolean }> = ({
  filter,
  setFilter,
  isBusinessManager,
}) => {
  const { dateRange, ...rest } = filter || {};

  const toDate = new Date(
    dateRange?.date?._year,
    dateRange?.date?._month - 1, // Subtract 1 from month
    dateRange?.date?._day
  );

  const customDateRange = {
    from: (() => {
      const fromDate = new Date(toDate);
      fromDate.setFullYear(fromDate.getFullYear() - (dateRange?.period?._years || 0));
      fromDate.setMonth(fromDate.getMonth() - (dateRange?.period?._months || 0));
      fromDate.setDate(fromDate.getDate() - (dateRange?.period?._days || 0));

      return '' + fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
    })(),
    to: '' + dateRange?.date?._year + '-' + dateRange?.date?._month + '-' + dateRange?.date?._day,
  };

  const analyticsConfig = useQuery(analyticsConfigQuery);

  const popiMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMinThreshold;
  const popiMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.popiMaxThreshold;
  const wrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMinThreshold;
  const wrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.wrMaxThreshold;
  const preOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMinThreshold;
  const preOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.preOpMaxThreshold;
  const cleaningMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMinThreshold;
  const cleaningMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.cleaningMaxThreshold;
  const pacuMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMinThreshold;
  const pacuMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.pacuMaxThreshold;
  const postOpMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMinThreshold;
  const postOpMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.postOpMaxThreshold;
  const inOrMinThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMinThreshold;
  const inOrMaxThreshold = analyticsConfig?.data?.analyticsConfiguration?.inOrMaxThreshold;

  const { data: prevData } = useQuery(dashboardQueryPrev, {
    variables: {
      filter: { dateRange: customDateRange, ...pickFilter(rest) },
      wrMinThreshold: wrMinThreshold,
      wrMaxThreshold: wrMaxThreshold,
      preOpMinThreshold: preOpMinThreshold,
      preOpMaxThreshold: preOpMaxThreshold,
      inOrMinThreshold: inOrMinThreshold,
      inOrMaxThreshold: inOrMaxThreshold,
      cleaningMinThreshold: cleaningMinThreshold,
      cleaningMaxThreshold: cleaningMaxThreshold,
      popiMinThreshold: popiMinThreshold,
      popiMaxThreshold: popiMaxThreshold,
      pacuMinThreshold: pacuMinThreshold,
      pacuMaxThreshold: pacuMaxThreshold,
      postOpMinThreshold: postOpMinThreshold,
      postOpMaxThreshold: postOpMaxThreshold,
    },
  });

  const prevStatistics = prevData?.dashboardPrev || [];

  const { data, loading } = useQuery(dashboardQuery, {
    variables: {
      filter: { dateRange: dateRange.toJSON(), ...pickFilter(rest) },
      wrMinThreshold: wrMinThreshold,
      wrMaxThreshold: wrMaxThreshold,
      preOpMinThreshold: preOpMinThreshold,
      preOpMaxThreshold: preOpMaxThreshold,
      inOrMinThreshold: inOrMinThreshold,
      inOrMaxThreshold: inOrMaxThreshold,
      cleaningMinThreshold: cleaningMinThreshold,
      cleaningMaxThreshold: cleaningMaxThreshold,
      popiMinThreshold: popiMinThreshold,
      popiMaxThreshold: popiMaxThreshold,
      pacuMinThreshold: pacuMinThreshold,
      pacuMaxThreshold: pacuMaxThreshold,
      postOpMinThreshold: postOpMinThreshold,
      postOpMaxThreshold: postOpMaxThreshold,
    },
    fetchPolicy: 'network-only',
  });

  const statistics = data?.dashboard || [];

  const totalPatientArray = [analyticsConfig?.data?.analyticsConfiguration?.totalPatientsThreshold];

  const configArray = [
    analyticsConfig?.data?.analyticsConfiguration?.waitingRoomTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.preopTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.readyForORThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.orUtilThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.orTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.cleaningTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.popiTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.pacuTimeThreshold,
    analyticsConfig?.data?.analyticsConfiguration?.postOpTimeThreshold,
  ];

  return (
    <>
      <PWAInstallBanner />

      {!isBusinessManager && <HospitalRating filter={filter} />}
      <AnalyticsLayout filter={filter} setFilter={setFilter} loading={loading}>
        <AnalyticsPanel title={statistics[0]?.title}>
          <GraphLayout
            key={`${statistics[0]?.id}-${0}`}
            {...statistics[0]}
            greenLineY={totalPatientArray[0]}
            data={statistics[0]}
            prevStat={prevStatistics[0]}
          />
        </AnalyticsPanel>
        <AnalyticsPanel title="Average Patient Journey">
          <PatientJourneyChart filter={{ dateRange: dateRange.toJSON(), ...pickFilter(rest) }} />
        </AnalyticsPanel>
        <AnalyticsPanel title="OR Performance">
          <ORPerformanceChart filter={{ dateRange: dateRange.toJSON(), ...pickFilter(rest) }} />
        </AnalyticsPanel>
        <AnalyticsPanel title="Procedure Cancellation Reasons">
          <ProcedureCancellationChart filter={{ dateRange: dateRange.toJSON(), ...pickFilter(rest) }} />
        </AnalyticsPanel>
        {statistics.slice(1).map((stat, i) => (
          <AnalyticsPanel title={stat.title}>
            <GraphLayout
              key={`${stat.id}-${i}`}
              {...stat}
              greenLineY={configArray[i]}
              data={statistics.slice(1)[i]}
              prevStat={prevStatistics.slice(1)[i]}
            />
          </AnalyticsPanel>
        ))}
      </AnalyticsLayout>
    </>
  );
};

export default GeneralAnalytics;
