import React, { useState } from 'react';
import styled from 'styled-components';
import responsive from '../../../se/utilities/responsive';
import AnalyticsPanel from './components/AnalyticsPanel';
import Distribution from './Distribution';

export const Value = styled.h2`
  display: flex;
  flex: 1;
  font-size: 2em;
  line-height: 1.3;
  margin: 0;
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  color: ${props => (!!props.color ? props.color : 'white')};
  ${responsive.md.andSmaller`
      font-size: 1.2em;
      margin-bottom: 0;
  `};
`;

export default ({
  title,
  value,
  distribution,
  unit,
  ratio,
  hideValue,
  tooltipLabel,
  isDecimal,
  children,
  noValue,
  color = undefined,
}) => {
  const [hidden, setHidden] = useState(window.innerWidth < 500);
  const computedValue =
    noValue || hideValue ? <span>&nbsp;</span> : [isDecimal ? value.toFixed(1) : value.toFixed(0), unit].join(' ');

  return (
    <AnalyticsPanel>
      <div style={{ display: 'flex' }}>{!noValue && <Value color={color}>{computedValue}</Value>}</div>
      {!hidden &&
        (children ? (
          children
        ) : (
          <Distribution
            color={color}
            distribution={distribution}
            unit={unit}
            label={title}
            ratio={ratio}
            tooltipLabel={tooltipLabel}
          />
        ))}
    </AnalyticsPanel>
  );
};
